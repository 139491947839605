@import "_settings";

.announcement-detail-modal {
  border-radius: 15px;
  max-width: 75%;

  @include when-on(mobile) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.announcement-detail {
  margin-bottom: 20px;
  padding-left: 2rem;
  padding-right: 2rem;
  // z-index: 10;
  // position: relative;
  height: auto;
  overflow-wrap: break-word;
  color: black;
  line-height: 25px;
}

.announcement-detail ol {
  list-style-type: decimal;
}

.announcement-detail ul {
  list-style-type: circle;
}

.announcement-detail a {
  text-decoration: underline;
  color: blue;
}
