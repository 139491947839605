@import "_settings";

.filter-list {
  .search-input--submit {
    &::before {
      @include convert-icon($mercury-accent-color);
    }
  }

  input {
    font-family: $font-main;
    padding-left: 2rem;
  }
}
