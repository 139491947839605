@import "_settings";

.auto-refresh {
  position: fixed;
  bottom: 5%;
  right: 5%;
  padding: 2rem;
  z-index: 2;
  background: $mercury-brand-color;
  border-radius: 15px;
  border: 2px solid $mercury-accent-color;

  &--button {
    color: $mercury-accent-color;
    padding: 1rem;
    background: $mercury-brand-color;
  }

  &--message {
    color: white;
  }
}
