@import "_settings";

.pill-filters {
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }

  input {
    padding-right: 1.25rem;
    background: transparent;
    color: $white;
    border-bottom: 1px solid $mercury-text-color;
    outline: none;

    &::placeholder {
      color: $mercury-text-color;
    }
  }

  .autocomplete {
    svg {
      fill: $white;
      right: 0;
      height: 4px;
      width: 20px;
    }
  }

  legend {
    color: $mercury-accent-color;
    font-size: .625rem;
    margin: 2px 0;
  }

  @keyframes pill-animation {
    0% {
      transform: translateX(100%);
    }

    75% {
      transform: translateX(-10%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .pill__animate {
    transition: transform 350ms ease-in;
    animation: pill-animation 500ms;
  }
}
