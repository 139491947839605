@import "_reset";
@import "_normalize";
@import "_settings";

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

body {
  height: 100%;
  background: $white;
  color: $mercury-text-color;
  font-family: $font-main;

  @include when-on(mobile) {
    width: $bp-mobile-width;
    font-size: 14px;
  }
  @include when-on(tablet) {
    width: $bp-tablet-width;
    font-size: 16px;
  }
  @include when-on(desktop) {
    margin: 0 auto;
    width: 100%;
    font-size: 16px;
  }

}

#app {
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  // sass-lint:disable-block indentation
  grid-template-areas:
    "header"
    "main"
    "footer";
}

.main {
  &-header {
    grid-area: header;
  }

  &-body {
    grid-area: main;
    width: 100%;
    height: 100%;

    @include when-on(desktop) {
      margin-top: calc(#{$header-height-desktop} + 2rem);
    }

    @include when-on(tablet) {
      margin-top: calc(#{$header-height-tablet} + 3rem);
    }

    @include when-on(mobile) {
      margin-top: calc(#{$header-height-mobile} + 1rem);
    }
  }

  &-footer {
    grid-area: footer;
    align-self: end;
  }
}

h1 {
  font-size: 1.5rem;
  font-family: $font-emphasis;
  font-weight: bold;
  color: $mercury-brand-color;

}

h2 {
  font-family: $font-accent;
  font-size: 1.125rem;
  font-weight: bold;
  color: $mercury-brand-color;
}

h3 {
  font-size: 1.5rem;
  font-family: $font-emphasis;
  font-weight: bold;
}

h4 {
  font-size: 1rem;
  font-family: $font-emphasis;
  font-weight: bold;
  color: $mercury-brand-color;
}

form {
  div {
    margin-top: 1rem;
  }

  label {
    font-family: $font-emphasis;
    font-weight: bold;
  }
}

a {
  cursor: pointer;
  text-decoration: none;
  color: $mercury-brand-color;
}

.button {
  &-submit {
    @extend %button-base;
    border-color: $mercury-brand-color;
    background: $mercury-brand-color;

    &:hover {
      border-color: $mercury-accent-color;
      background: $mercury-accent-color;
    }

    &:disabled {
      border-color: lighten($mercury-brand-color, 20%);
      background: lighten($mercury-brand-color, 20%);
    }
  }

  &-register {
    @extend %button-base;
    border-color: $mercury-text-color;
    background: $mercury-text-color;

    &:hover {
      color: $mercury-text-color;
    }

    &:disabled {
      border-color: darken($mercury-text-color, 20%);
      background: darken($mercury-text-color, 20%);
    }
  }

  &-cancel {
    @extend %button-base;
    border-color: $white;
    background: $white;
    color: $mercury-brand-color;
    width: 150px;

    &:hover {
      color: $mercury-text-color;
      border-color: darken($white, 20%);
      background: darken($white, 20%);
    }

    &:disabled {
      border-color: darken($white, 20%);
      background: darken($white, 20%);
    }
  }

  &-contact {
    @extend %button-base;
    width: 150px;
    border-color: $mercury-accent-color;
    background: $mercury-accent-color;

    &:hover {
      border-color: $mercury-accent-color;
      background: $mercury-accent-color-light;
    }
  }
}

.accent-toggle {
  align-self: center;

  &.react-toggle--checked {
    align-self: center;

    &:hover:not(.react-toggle--disabled) {
      .react-toggle-track {
        background-color: $mercury-accent-color-light;
      }
    }

    .react-toggle-track {
      background-color: $mercury-accent-color;
    }

    .react-toggle-thumb {
      border: 1px solid $white;
    }
  }
}

.hide {
  display: none;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  #ie-user-banner {
    width: 100%;
    padding: 3rem;
    font-size: 2.5rem;
    line-height: 1.5;
    color: $mercury-brand-color;
    text-align: center;

    &::before {
      content: "Your browser is not supported by Bloomberg Mercury. For the best experience, please visit https://mercury.bloomberg.com/ using a Chrome or Firefox browser.";
      width: 75%;
      margin: 0 auto;
      display: block;
    }
  }

  #app {
    display: none;
    height: 0;
  }
}
