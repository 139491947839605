// taken from https://codepen.io/sosuke/pen/Pjoqqp
@mixin convert-icon($color) {
  @if $color == $mercury-accent-color {
    filter: brightness(0) saturate(100%) invert(68%) sepia(46%) saturate(940%) hue-rotate(344deg) brightness(102%) contrast(92%);
  } @if $color == $mercury-accent-color-on-light {
    filter: brightness(0) invert(41%) sepia(43%) saturate(747%) hue-rotate(1deg) brightness(93%) contrast(95%);
  } @else if $color == $mercury-text-color {
    filter: invert(27%) sepia(0%) saturate(1091%) hue-rotate(156deg) brightness(94%) contrast(85%);
  } @else if $color == $white {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(20deg) brightness(118%) contrast(114%);
  }  @else if $color == $mercury-inactive-color {
    filter: brightness(0) saturate(100%) invert(73%) sepia(4%) saturate(26%) hue-rotate(42deg) brightness(86%) contrast(81%);
  }  @else if $color == $mercury-brand-color {
    filter: brightness(0)
  }
}

%icon {
  content: "";
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 20px;
  height: 14px;
}

// Submit and Register buttons
%button-base {
  border: 1px solid;
  border-radius: 7px;
  width: 98px;
  padding: .5rem;
  color: $white;
  background-color: transparent;
  outline: none;
  font-family: $font-main;

  &:hover {
    background: $white;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
