@import "_settings";

.search-input {
  position: relative;
  $search-input-icon-w: 1rem;

  &--query {
    margin: 0;
    padding: 0;

    input {
      display: inline-block;
      width: 100%;
      margin: 0;
      border: 0;
      outline: none;

      &::-webkit-search-cancel-button {
        @extend %icon;
        background-image: url("~icons/close-delete@3x.png");
        width: 10px;
        display: inline-block;
      }
    }
  }

  &--submit {
    position: absolute;
    background: transparent;
    width: $search-input-icon-w;
    height: $search-input-icon-w;
    outline: none;
    top: 50%;
    transform: translate(0, -50%);

    @include when-on(desktop) {
      left: .5 * $search-input-icon-w;
    }

    @include when-less-than(desktop) {
      left: .5rem;
    }

    &::before {
      display: inline-block;
      content: "";
      background-color: $mercury-inactive-color;
      -webkit-mask-image: url("~icons/search.svg");
      mask-image: url("~icons/search.svg");
      width: $search-input-icon-w;
      height: $search-input-icon-w;
    }
  }
}
