@import "_settings";

.error-page {
  &__title {
    font-size: 2rem;
    font-family: $font-emphasis;
    margin-top: 4rem;
  }

  &__message {
    color: $mercury-unavailable-color;
    margin-top: 2rem;
  }

  &__id {
    margin-top: .5rem;
  }
}
