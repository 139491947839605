@import "_settings";


.griditem {
  position: relative;
  margin-bottom: 5px;
  overflow: hidden;
  background: $mercury-brand-color;
  display: flex;
  justify-content: center;
  align-items: center;

  perspective: 1000px;

  @include when-on(desktop) {
    margin-right: .85rem;
    width: 360px;
    height: 240px;
  }

  @include when-on(tablet) {
    flex-basis: 33%;

    &:nth-child(3n + 1) {
      margin-right: 1px;
    }

    &:nth-child(3n + 2) {
      margin-left: auto;
    }

    &:nth-child(3n) {
      margin-left: auto;
    }
  }

  @include when-on(mobile) {
    flex-basis: 100%;
    margin-bottom: 1rem;
  }

  &--news {
    color: $white;
    display: flex;

    .news-contents__title {
      transform: translate(0, 50%);
      align-self: center;
    }

    a {
      display: block;
      width: 100%;
    }

    @include when-on(mobile) {
      display: block;
      min-height: auto;
      background: transparent;
      color: $mercury-brand-color;
    }
  }

  $options-height: 60px; // options height

  &:hover {
    .griditem-footer {
      transform: translate(0, 0);
    }

    .griditem-title {
      white-space: normal;
    }
  }

  &-info {
    position: absolute;
    overflow: hidden;
    top: 0;
    width: 100%;
    color: $white;
    padding: .5rem;
    display: flex;

    @include when-on(mobile) {
      position: relative;
      padding: .25rem 0;
      color: $mercury-brand-color;
    }

    &__wire {
      font-size: .75rem;
      font-weight: bold;
      font-family: $font-accent;
      text-align: center;
      background: $white;
      color: $mercury-brand-color;
      padding: 2px .25rem;

      @include when-on(mobile) {
        padding: 2px 0;
      }
    }

    time {
      font-size: .65rem;
      text-shadow: 0 0 1px rgba($mercury-brand-color, .5);
      flex-grow: 1;
      text-align: center;
      align-self: center;
    }

    %info-icon {
      @extend %icon;
      display: inline-block;
      @include convert-icon($white);

      @include when-on(mobile) {
        @include convert-icon($mercury-brand-color);
      }
    }

    &__icon {
      margin-left: auto;

      &--image {
        &::before {
          @extend %info-icon;
          background-image: url("~icons/photo_ONLY-ORG@3x.png");
        }
      }

      &--news {
        &::before {
          @extend %info-icon;
          background-image: url("~icons/ARTICLE_icon-ORG@3x.png");
        }
      }

      &--video {
        &::before {
          @extend %info-icon;
          background-image: url("~icons/video_only_ORG@3x.png");
        }
      }

      &--group {
        background: transparent;
        color: $white;

        &:hover {
          color: $mercury-accent-color;

          &::before {
            @include convert-icon($mercury-accent-color);
            background-image: url("~icons/MEID_Group@3x.png");
          }
        }

        &::before {
          @extend %info-icon;
          margin-right: 2px;
          background-image: url("~icons/MEID_Group@3x.png");
        }

        @include when-on(mobile) {
          color: $mercury-brand-color;
        }
      }
    }
  }

  &-footer {
    position: absolute;
    overflow: hidden;
    bottom: 0;
    width: 100%;
    color: $white;

    @include when-on(desktop) {
      transition: all 300ms ease-in-out;
      transform: translate(0, calc(100% - 50px));
    }

    @include when-on(tablet) {
      transition: all 300ms ease-in-out;
      transform: translate(0, calc(100% - 60px));
    }

    @include when-on(mobile) {
      position: relative;
      bottom: auto;
      color: $mercury-brand-color;
    }
  }

  &-title {
    color: $white;
    display: block;
    padding: 1rem .5rem;
    width: 100%;
    background: rgba($mercury-brand-color, .65);
    font-weight: bold;
    font-size: 1.125rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;

    @include when-on(mobile) {
      background: transparent;
      color: $mercury-brand-color;
      padding: .5rem 0;
      white-space: normal;
    }
  }

  &-summary {
    font-size: .75rem;
    padding: .5rem;
    word-wrap: break-word;
    display: block;
    color: $white;
    background: rgba($mercury-brand-color, .65);

    @include when-on(mobile) {
      display: none; // don't display summaries on mobile
    }
  }

  &-options {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    background: rgba($mercury-brand-color, .65);

    > * {
      background-color: transparent;
      color: $white;

      &:hover {
        background: $mercury-brand-color;
      }
    }

    @include when-on(mobile) {
      background: transparent;
    }

    %griditem-icon {
      @extend %icon;
      display: block;
      margin: 0 auto .5rem;
      background-position: center;

      @include when-on(mobile) {
        display: inline;
        margin-right: .25rem;
        padding: .5rem;
      }
    }

    .button {
      padding: .75rem .5rem;
      font-size: .8rem;

      @include when-on(mobile) {
        padding: .25rem 0;
        color: $mercury-brand-color;
        flex-basis: auto !important; // sass-lint:disable-line no-important
      }

      &--share {
        &::before {
          @extend %griditem-icon;
          filter: invert(100%);
          background-image: url("~icons/share@3x.png");

          @include when-on(mobile) {
            @include convert-icon($mercury-accent-color);
          }
        }
      }

      &--add {
        &::before {
          @extend %griditem-icon;
          filter: invert(100%);
          background-image: url("~icons/add@3x.png");

          @include when-on(mobile) {
            @include convert-icon($mercury-accent-color);
          }
        }
      }

      &--remove {
        &::before {
          @extend %griditem-icon;
          @include convert-icon($white);
          background-image: url("~icons/YELLOW_REMOVE_ITEMS@3x.png");
          height: 16px;

          @include when-on(mobile) {
            @include convert-icon($mercury-accent-color);
          }
        }

        &-added {
          &::before {
            @extend %griditem-icon;
            background-image: url("~icons/ICON_3x_selected_asset.png");

            @include when-on(mobile) {
              @include convert-icon($mercury-accent-color);
            }
          }
        }
      }

      &--add-added {
        &::before {
          @extend %griditem-icon;
          background-image: url("~icons/ICON_3x_selected_asset.png");

          @include when-on(mobile) {
            @include convert-icon($mercury-accent-color);
          }
        }
      }

      &--download {
        &::before {
          @extend %griditem-icon;
          filter: invert(100%);
          background-image: url("~icons/download-comp@3x.png");

          @include when-on(mobile) {
            @include convert-icon($mercury-accent-color);
          }
        }
      }

      &--saveas {
        &::before {
          @extend %griditem-icon;
          @include convert-icon($white);
          background-image: url("~icons/ICON_3x_add_collection.png");

          @include when-on(mobile) {
            @include convert-icon($mercury-accent-color);
          }
        }
      }

      &--delete {
        &::before {
          @extend %griditem-icon;
          filter: invert(100%);
          background-image: url("~icons/close-delete@3x.png");

          @include when-on(mobile) {
            @include convert-icon($mercury-accent-color);
          }
        }
      }
    }
  }
}
