@import "_settings";

.spotlight {
  margin-bottom: 3rem;

  @include when-on(desktop) {
    width: $bp-desktop-width;
    margin: 0 auto 3rem;
  }

  &.dark-theme {
    background-color: $mercury-brand-color;
    padding: 8rem 0;
    width: 100%;

    @include when-less-than(desktop) {
      padding: 5rem 0;
      margin: 0 0 3rem;
    }

    h3 {
      background: $white;
      color: $mercury-brand-color;
    }

    .spotlight-wrapper {
      display: block;
      width: $bp-desktop-width;
      margin: 0 auto;
    }
  }

  h3 {
    display: inline-block;
    color: $mercury-accent-color;
    background: $mercury-alt-dark-background-color;
    width: 50%;
    padding-top: calc((78px - 1.5rem) / 2);
    padding-left: calc(.5rem + 78px);
    font-family: $font-main;
    height: 78px;
    position: relative;

    @include when-on(desktop) {
      font-size: 1.5rem;
    }

    @include when-less-than(desktop) {
      font-size: 1.25rem;
    }

    @include when-on(mobile) {
      width: 100%;
    }

    &::before {
      @extend %icon;
      width: 78px;
      height: 78px;
      background-size: 40px 27px;
      background-position: center;
      background-color: $mercury-accent-color;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &--photo {
    &::before {
      background-image: url("~icons/B_photo@3x.png");
    }
  }

  &--news {
    &::before {
      background-image: url("~icons/B_news@3x.png");
    }
  }

  &--collection {
    &::before {
      background-image: url("~icons/B_collection@3x.png");
    }
  }

  &--video {
    &::before {
      background-image: url("~icons/B_video@3x.png");
    }
  }

  &-container {
    display: flex;

    @include when-on(desktop) {
      padding: 0 78px;
    }

    @include when-on(mobile) {
      flex-wrap: wrap;
    }
  }

  .homepage-story {
    @include when-on(mobile) {
      flex-basis: 100%;
    }
  }

  .homepage-story-title {
    font-size: 1.875rem;
    bottom: 1rem;

    @include when-on(tablet) {
      bottom: .5rem;
    }

    @include when-less-than(tablet) {
      font-size: 1.2rem;
    }
  }
}
