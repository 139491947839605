@import "_settings";

.notification {
  z-index: 100;
  position: fixed;
  top: 1rem;
  left: 50%;
  margin-left: -25%;
  width: 50%;
  padding: 2rem;
  color: $mercury-brand-color;
  font-size: 1.1rem;

  @include when-on(mobile) {
    width: 80%;
    margin-left: -40%;
  }

  &.error {
    background: $white;
    border: 1px solid $mercury-brand-color;
  }


  &.closed {
    display: none;
  }

  details {
    outline: 0;

    &.empty {
      summary::-webkit-details-marker {
        visibility: hidden;
      }
    }
  }

  summary {
    outline: 0;

    span {
      text-transform: capitalize;
    }
  }

  &--close {
    color: $mercury-brand-color;
    @extend %button-base;
    float: right;

    &:hover {
      background: $mercury-brand-color;
      color: $white;
    }
  }
}
