@import "_settings";

.collection {
  padding: 0 1rem;

  &--name {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 26px;

    h2 {
      margin: 0 auto;
      display: inline-block;
      width: auto;
      text-align: center;
      position: relative;
      font-size: 1.5rem;
    }

    &:hover {
      .collection--name__edit {
        &::before {
          background-image: url("~icons/add@3x.png");
        }
      }
    }

    &__edit {
      position: absolute;
      left: calc(-20px - .25rem);
      background-color: transparent;

      &::before {
        @extend %icon;
        background-image: url("~icons/Collection_added@3x.png");
        margin-right: 4px;
      }
    }

    form {
      margin: 0 auto;
      position: relative;

      div {
        margin: 0;
      }

      button {
        background-color: transparent;
        position: absolute;
        left: calc(-20px - .25rem);
        top: 50%;
        transform: translate(0, -50%);


        &::before {
          @extend %icon;
          background-image: url("~icons/add@3x.png");
        }
      }

      input {
        border: 0;
        background: $mercury-alt-background-color;
      }
    }
  }

  .items {
    margin-top: 1rem;
  }
}
