@import "_settings";

.items {
  &.grid {
    display: flex;
    flex-wrap: wrap;
  }


  &.list {
    @include when-on(mobile) {
      padding: 0 5px;
    }
  }

  &-loader {
    width: 100%;
  }

  .story-download {
    @include when-on(mobile) {
      display: none; // don't show download button for mobile users
    }
  }
}
