@import "_settings";

.item-new {
  padding: 1rem 2rem;

  @include when-on(desktop) {
    width: $bp-mobile;
  }

  @include when-less-than(desktop) {
    width: 100%;
  }

  h4 {
    color: $white;
    text-align: center;
  }

  h5 {
    padding-top: 3rem;
    color: $mercury-accent-color;
    text-align: center;
    font-size: 1.5rem;
  }

  form {
    padding: 4rem 0;
    display: flex;
    flex-direction: column;

    label {
      color: $white;
      font-family: $font-main;
    }

    input[type=text] {
      font-family: $font-main;
      background: $mercury-text-color;
      border: 0;
      color: $white;
      font-size: 1.125rem;
    }
  }

  p {
    padding-top: 3rem;
  }

  &-actions {
    display: inline-block;
    margin-top: 4rem;
    align-self: flex-end;

    svg {
      display: inline-block;
      margin-left: 4px;
    }

    @include when-on(mobile) {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }

  .button-contact {
    color: $mercury-brand-color;
    margin-left: 1rem;
  }
}
