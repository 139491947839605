@import "_settings";

.series-catalog-list {
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  margin: .7rem;

  @include when-on(tablet) {
    padding: 1rem .5rem;
  }

  @include when-on(desktop) {
    padding: 1.5rem;
  }

  &--item {
    padding: 1rem 0;
    flex-basis: 100%;
    max-width: 400px;
    min-width: 350px;

    @include when-on(tablet) {
      flex-basis: 50%;
      padding: 1rem .5rem;
    }

    @include when-on(desktop) {
      flex-basis: 33.33%;
      padding: 1.5rem;
    }

    img {
      width: 100%;
      height: 205px;
    }

    &-title {
      padding: 1rem 0;
      font-weight: bolder;
      font-family: $font-emphasis;
    }

    &-summary {
      color: $mercury-text-color;
    }
  }
}
