@import "_settings";

.usage {
  width: 80%;
  margin: auto;

  %usage-data-format {
    padding: 1rem 0;
    text-align: center;
    border-left: 1px dotted $mercury-brand-color;
    border-collapse: collapse;
    font-size: 2.25rem;
  }

  &-data {
    @extend %usage-data-format;

    &:first-child {
      font-size: 1rem;
      border: 0;
      text-align: left;
    }

    &--value {
      margin: .5rem 0;
    }

    &--category {
      color: $mercury-accent-color-on-light;
      font-size: .8rem;
    }

    &-highlight {
      @extend %usage-data-format;

      &--value {
        margin: .5rem 0;
        font-weight: bold;
        color: $mercury-brand-color;
      }

      &--category {
        color: $mercury-accent-color-on-light;
        font-size: .8rem;
      }
    }
  }

  &-container {
    width: 90%;
    margin: auto;

    &--header-row {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
      text-align: center;
      padding: 15px;
    }

    &--data-row {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
      padding: 1rem;
      border-bottom: 1px solid $mercury-brand-color;

      &:last-child {
        border: 0;
      }
    }
  }

  section {
    border-top: 1px solid $mercury-alt-background-color;
    padding: 1rem 0;
  }

  &--history {
    .usage-container {
      margin-top: 1rem;

      &--header-row {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

        h4 {
          text-align: left;
        }
      }

      &--data-row {
        font-size: .75rem;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        border: 0;
      }
    }

    &__type {
      text-transform: uppercase;
    }
  }
}
