@import "_settings";

.video-content-options {
  display: flex;

  & > div {
    flex-basis: 33.33%;
    text-align: center;

    button,
    .button--download {
      padding: .75rem;
      font-size: .85rem;
      color: $mercury-brand-color;
      font-family: $font-emphasis;
    }
  }

  .label-image {
    @extend %icon;
    width: 100%;
    height: 1.5rem;
    margin-bottom: .5rem;
    background-size: contain;
    background-position: center;
    @include convert-icon($mercury-accent-color);
  }

  &--share-button .label-image {
    background-image: url("~icons/COLLECTION_CONTROLS@3x_SHARE.png");
  }

  &--collection-button .label-image {
    background-image: url("~icons/B_add@3x.png");
  }

  &--download-button .label-image {
    background-image: url("~icons/download-comp@3x.png");
  }

  .share-button,
  .add-button {
    background-color: transparent;
  }

}
