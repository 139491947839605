@import "_settings";

%tagline-border {
  content: "";
  display: block;
  width: 100%;
  height: 5px;
}

.tagline {
  background-color: $mercury-alt-dark-background-color;
  color: $white;
  position: relative;

  &.closed {
    display: none;
    margin-bottom: 0;
  }

  &::before {
    @extend %tagline-border;
    background-image: linear-gradient(to right, $mercury-accent-color-light, $mercury-accent-color-dark);
  }

  &::after {
    @extend %tagline-border;
    background-image: linear-gradient(to left, $mercury-accent-color-light, $mercury-accent-color-dark);
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 0;
    background: transparent;

    &::before {
      @extend %icon;
      width: 59px;
      height: 50px;
      background-image: url("~icons/B_close@3x.png");
    }
  }

  &__wrapper {
    padding: 1.5rem 0;
  }

  h2 {
    color: $white;
    margin: 0 auto;
    font-size: 2rem;
    line-height: 1.13;
    text-align: center;
    font-family: $font-emphasis;

    @include when-on(mobile) {
      font-size: 1.25rem;
      line-height: 1.25;
    }

    @include when-less-than(desktop) {
      width: 100%;
      padding: 0 1rem;
    }
  }

  &__actions {
    color: $white;
    display: flex;
    justify-content: space-between;
    width: 1000px;
    margin: 1.5rem auto 0;

    @include when-less-than(desktop) {
      padding: 0 1rem;
      width: 100%;
    }

    @include when-on(mobile) {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  &__action {
    display: inline-grid;
    grid-column-gap: 1rem;
    grid-template-columns: 60px auto;
    grid-template-areas:
        "icon label" // sass-lint:disable-line indentation
        "icon description"; // sass-lint:disable-line indentation

    @include when-on(mobile) {
      width: 100%;
      grid-template-columns: 40px auto;
      margin-bottom: 1rem;
    }

    i {
      @extend %icon;
      grid-area: icon;
      height: 50px;
      width: 50px;

      @include when-less-than(desktop) {
        height: 40px;
        width: 40px;
      }
    }

    &--search {
      background-image: url("~icons/B_search@3x.png");
    }

    &--add {
      background-image: url("~icons/B_add@3x.png");
    }

    &--download {
      background-image: url("~icons/B_download@3x.png");
    }

    label {
      grid-area: label;
      cursor: pointer;
      font-family: $font-emphasis;
      font-size: 1.5rem;

      @include when-less-than(desktop) {
        font-size: 1.25rem;
      }
    }

    p {
      grid-area: description;
      font-size: 1.25rem;
      line-height: 1.15;

      @include when-less-than(desktop) {
        font-size: 1.25rem;
        line-height: 1.2;
      }
    }

    &--angle {
      &::after {
        content: "";
        display: inline-block;
        border-right: 1px solid $mercury-text-color;
        border-bottom: 1px solid $mercury-text-color;
        width: 40px;
        height: 40px;
        transform: translate(0, 10px) rotate(-45deg);

        @include when-less-than(desktop) {
          height: 25px;
          width: 25px;
          transform: translate(-10px, 20px) rotate(-45deg);
          margin-right: 1rem;
        }

        @include when-on(mobile) {
          display: none;
          margin: 0;
        }
      }
    }
  }
}
