@import "_settings";

.confirmation {
  padding: 2rem;

  h4 {
    color: $mercury-accent-color;
  }

  &--actions {
    display: flex;
    justify-content: space-between;

    svg {
      display: inline-block;
      margin-left: 4px;
    }
  }

  &--error {
    display: block;
    flex: 1 0 100%;
    margin-top: 1rem;
  }
}
