@import "_settings";

.accessdenied {
  width: $bp-mobile;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-align: center;

  @include when-on(mobile) {
    padding: 0 1rem;
    width: $bp-mobile-width;
  }

  p {
    margin-top: 1rem;
    margin-bottom: 3rem;
    line-height: 1.5;
    font-size: 1.3rem;
  }

  .button-contact {
    margin-left: auto;
  }
}
