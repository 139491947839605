@import "_settings";

.dropdown {
  &-container {
    position: relative;
    min-width: 8rem;

    @include when-on(mobile) {
      width: 100%;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    padding: 1rem;
    width: 100%;
    text-align: right;
    color: $mercury-brand-color;
    background-color: $white;

    .dark-theme > & {
      justify-content: flex-start;
      background-color: $mercury-brand-color;
      color: $white;

      .dropdown-title--icon {
        top: 5px;
        width: 10px;
        position: relative;
        margin-left: 2.5rem;
        @include convert-icon($mercury-accent-color);
        background-image: url("~icons/triangle@3x.png");

        @include when-less-than(desktop) {
          margin-left: 1rem;
        }
      }

      @include when-on(desktop) {
        padding: 1rem;
      }

      @include when-less-than(desktop) {
        padding: .5rem .5rem .5rem 1rem;
      }
    }

    &:hover {
      background-color: $mercury-brand-color;
      color: $white;

      .dropdown-title--icon {
        @include convert-icon($white);
        transform: rotate(45deg);
      }

      .dark-theme > & {
        .dropdown-title--icon {
          transform: rotate(0);
        }
      }
    }

    &:focus {
      outline: 0;
    }

    &--icon {
      @extend %icon;
      @include convert-icon($mercury-brand-color);
      background-image: url("~icons/profile@3x.png");
      margin-left: .5rem;
      transition: .2s;
    }
  }

  &-list {
    z-index: 1;
    position: absolute;
    width: 100%;
    overflow: visible;
    background-color: $white;
    border: 1px solid $mercury-brand-color;

    li {
      padding: 1rem;

      &:hover {
        a {
          color: $white;
        }
        background-color: $mercury-brand-color;
      }
    }

    .dark-theme > & {
      background-color: $mercury-brand-color;

      li {
        color: $white;

        &:hover {
          color: $mercury-accent-color;
        }
      }
    }
  }
}
