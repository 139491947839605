@import "_settings";

.datepicker {

  %calendar-day-format {
    background-color: transparent;
    border: 1px solid $mercury-accent-color;
    color: $white;
  }

  %calendar-label-format {
    background: $mercury-brand-color;
    color: $white;
  }

  .react-datepicker {
    @extend %calendar-label-format;

    &__triangle {
      display: none;
    }

    &__header {
      @extend %calendar-label-format;
    }

    &__current-month {
      color: $white;
    }

    &__day {
      color: $white;

      &:hover {
        background-color: $mercury-accent-color;
      }

      &--disabled {
        color: $mercury-text-color;
      }

      &-name {
        color: $white;
      }

      &--in-selecting-range {
        background-color: $mercury-accent-color;
      }

      &--in-range {
        @extend %calendar-day-format;
      }

      &--keyboard-selected {
        @extend %calendar-day-format;
      }

      &--selected {
        @extend %calendar-day-format;
      }

      &--range-start {
        @extend %calendar-day-format;
      }

      &--range-end {
        @extend %calendar-day-format;
      }

      &--today {
        font-weight: bold;
        border: 1px solid $white;
      }
    }

    &__month {
      &-read-view--down-arrow {
        top: 2px;
        left: .8rem;
        margin-left: 0;
        border-top-color: $mercury-accent-color;
      }

      &-dropdown {
        @extend %calendar-label-format;
        width: 80%;
      }

      &-read-view {
        margin-right: 5px;
        margin-top: 5px;
      }

      &-option {
        &:hover {
          background-color: $mercury-text-color;
        }
      }
    }

    &__year {
      &-read-view--down-arrow {
        top: 2px;
        right: -1rem;
        margin-left: 0;
        border-top-color: $mercury-accent-color;
      }

      &-dropdown {
        @extend %calendar-label-format;
        width: 80%;
      }

      &-option {
        &:hover {
          background-color: $mercury-text-color;
        }
      }
    }

    &-wrapper {
      input {
        width: 100px;
        padding: 2px 4px;
        background: transparent;
        cursor: pointer;
        color: $mercury-accent-color;
        border-bottom: 1px dotted $mercury-text-color;
      }
    }

    &__close-icon {
      visibility: hidden;

      &::after {
        @extend %calendar-day-format;
        visibility: visible;
        color: $white;
        right: -2rem;
        top: 4px;
        padding: 0;
      }
    }
  }
}
