@import "_settings";

.activation {
  width: $bp-mobile;
  margin: 0 auto;

  @include when-on(mobile) {
    padding: 0 1rem;
    width: $bp-mobile-width;
  }

  p {
    margin-top: 1rem;
    line-height: 1.5;
    font-size: 1.125rem;
  }

  a {
    color: $mercury-accent-color;
  }

  &--error {
    color: $mercury-unavailable-color;
  }
}
