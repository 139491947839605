@import "_settings";

.contact-us {
  h1 {
    text-align: center;
  }

  &-form {
    width: $bp-mobile;
    margin: 0 auto;

    &-columns {
      display: flex;

      @include when-wider-than(tablet) {
        justify-content: space-between;
      }

      @include when-on(mobile) {
        flex-wrap: wrap;
      }

      div {
        @include when-wider-than(tablet) {
          flex-basis: 48%;
        }

        @include when-on(mobile) {
          flex-basis: 100%;
        }
      }
    }

    &-textarea {
      margin-top: 2rem;

      textarea {
        resize: vertical;
      }
    }

    &-submit {
      margin-top: 1rem;
      text-align: right;

      button {
        text-align: center;
      }
    }
  }
}
