@import "_settings";

.story-package {
  margin-bottom: 3rem;

  @include when-on(desktop) {
    width: $bp-desktop-width;
    margin: 0 auto 3rem;
  }

  h3 {
    display: inline-block;
    color: $mercury-brand-color;
    background: $white;
    width: 50%;
    padding-top: calc((78px - 1.5rem) / 2);
    padding-left: calc(.5rem + 78px);
    font-family: $font-accent;
    height: 78px;
    position: relative;

    @include when-on(desktop) {
      font-size: 2rem;
    }

    @include when-less-than(desktop) {
      font-size: 1.25rem;
    }

    @include when-on(mobile) {
      width: 100%;
    }
  }

  &-container {
    display: flex;
    flex-wrap: wrap;

    @include when-on(desktop) {
      padding: 0 78px;
    }
  }

}

.topic-list {
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  margin: .7rem;

  @include when-on(tablet) {
    padding: 1rem .5rem;
  }

  @include when-on(desktop) {
    padding: 1.5rem;
  }

  &--item {
    padding: 1rem 0;
    flex-basis: 100%;
    max-width: 400px;
    min-width: 350px;

    @include when-on(tablet) {
      flex-basis: 50%;
      padding: 1rem .5rem;
    }

    @include when-on(desktop) {
      flex-basis: 33.33%;
      padding: 1.5rem;
    }

    img {
      width: 100%;
      height: 205px;
    }

    &-title {
      padding: 1rem 0;
      font-weight: bolder;
      font-family: $font-emphasis;
    }

    &-summary {
      color: $mercury-text-color;
    }
  }
}
