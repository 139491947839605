@import "_settings";

.collections {
  padding: 0 1rem;

  h2 {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;

    &::before {
      @extend %icon;
      background-image: url("~icons/Collections@3x.png");
      margin-right: 4px;
    }
  }

  &-list {
    margin-top: 1rem;
  }

  &--nav {
    margin-top: .25rem;

    &--new-collection {
      font-family: $font-main;
      background-color: transparent;
      color: $mercury-text-color;
      margin-left: 1rem;

      &::before {
        @extend %icon;
        background-image: url("~icons/ICON_3x_add_collection.png");
        margin-right: 4px;
      }
    }

    &--filter {
      display: inline-block;
    }
  }
}
