@import "_settings";

.video-series-catalog {
  max-width: 1300px;
  margin: auto;

  @include when-on(tablet) {
    width: 85%;
  }

  @include when-on(desktop) {
    width: 80%;
  }
}
