@import "_settings";

.quickfind {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: .75rem 0;
  background-color: $mercury-brand-color;
  box-shadow: inset 1px 0 0 0 $mercury-text-color;

  &--option {
    display: block;
    cursor: pointer;
    padding: 0 1rem;
    margin-bottom: 1.5rem;
    color: $mercury-inactive-color;

    &:first-child {
      margin-top: 1.5rem;
    }

    &:hover,
    &.selected {
      color: $white;
      content: "";
      box-shadow: inset 2px 0 0 0 $mercury-accent-color;
      display: block;

      span::after {
        color: $mercury-accent-color;
      }
    }

    span {
      &::after {
        content: ">";
        display: inline-block;
        margin-left: 5px;
      }


    }
  }

  &--sub-options {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: $mercury-brand-color;
    box-shadow: inset 1px 0 0 0 $mercury-text-color;
  }
}
