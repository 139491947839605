@import "_settings";

%icon {
  @include convert-icon($mercury-text-color);
  content: "";
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  margin-left: .5rem;

  &:hover {
    @include convert-icon($mercury-accent-color);
  }
}

.modal {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($mercury-brand-color, .6);
  overflow-y: auto;
}

.modal-main {
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  background: $white;
  width: auto;
  height: auto;
  padding: 1rem;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include when-less-than(tablet) {
    top: 5rem;
    left: 0;
    transform: none;
  }

  &-close-button {
    background-color: transparent;
    text-align: right;

    &::after {
      @extend %icon;
      background-image: url("~icons/close-delete@3x.png");
    }
  }

  &.dark {
    background: $mercury-brand-color;
    color: $white;

    .modal-main-close-button {
      &::after {
        @include convert-icon($white);
      }
    }

    p {
      margin-top: 1rem;
      margin-bottom: 3rem;
      line-height: 1.5;
      font-size: 1.3rem;
      color: $white;
    }
  }
}

