@import "_settings";

.contributors {
  margin-bottom: 3rem;

  h3 {
    text-transform: uppercase;
    color: $mercury-brand-color;

    @include when-on(desktop) {
      font-weight: 900;
      font-size: 2rem;
    }

    @include when-less-than(desktop) {
      font-size: 1.125rem;
      font-weight: bold;
      padding-left: 1.5rem;
    }
  }

  &-container {
    border-top: 1px solid $mercury-text-color;
    border-bottom: 1px solid $mercury-text-color;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;

    @include when-on(desktop) {
      padding: 2.5rem;
    }

    @include when-on(tablet) {
      padding: 1.25rem;
    }

    @include when-on(mobile) {
      flex-wrap: wrap;
      padding: 1.5rem;
    }
  }
}
