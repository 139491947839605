@import "_settings";

.main-header {
  position: fixed;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  background: $white;
  width: 100vw;

  @include when-on(desktop) {
    padding: 5px 1rem;
  }

  @include when-on(tablet) {
    padding-bottom: .5rem;
  }

  @include when-on(mobile) {
    padding: 5px;
    display: grid;  // switch to grid layout for mobile
    grid-template-columns: auto auto;
  }

  &-logo {
    flex: inherit;

    @include when-on(desktop) {
      flex-basis: calc(500px + .25rem);
      padding-right: 2rem;

      img {
        max-width: 700px;
      }
    }

    @include when-on(tablet) {
      padding-right: 1.25rem;
      padding-left: .5rem;
      padding-top: .5rem;
      flex-basis: calc(350px + 1.25rem);
    }

    @include when-on(mobile) {
      grid-row: 1 / span 1;
      grid-column: 1 / span 2;
      padding-left: .3rem;

      img {
        max-width: 400px;
        margin: 0 auto;
      }
    }

    .logo {
      color: $mercury-brand-color;
      font-family: $font-accent;
      font-weight: bold;
      display: inline-flex;

      &,
      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  &-searchbar {
    flex: auto;
    display: inline-flex;

    @include when-on(tablet) {
      padding-top: .5rem;
    }

    @include when-on(mobile) {
      padding-right: .5rem;
      grid-row: 2 / span 1;
      grid-column: 2 / span 2;
    }
  }

  &-bookmarks {
    flex: 0;
    text-align: center;

    padding: .5rem;

    @include when-on(tablet) {
      flex-basis: 35px;
      padding-top: 1rem;
    }

    @include when-on(mobile) {
      padding: 0 .5rem;
      grid-row: 2 / span 1;
      grid-column: 1 / span 1;
    }

    .bookmarks {
      background: transparent;

      &::before {
        @extend %icon;
        @include convert-icon($mercury-accent-color);
        cursor: pointer;
        height: 24px;
        background-image: url("~icons/bookmark@3x.png");
      }
    }
  }

  &-user-actions {
    flex: none;

    @include when-on(tablet) {
      padding-top: .5rem;
    }

    @include when-on(mobile) {
      flex-basis: 35px;
      grid-row: 1 / span 1;
      grid-column: 3 / span 1;
    }
  }
}
