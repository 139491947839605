@import "_settings";

.mercury-selectdropdown {
  label {
    display: block;
    color: $mercury-brand-color;
    margin-bottom: .5rem;
  }

  select {
    /* General styling */
    border-radius: 0;
    border: 0;
    width: 100%;
    background: transparent;
    color: $mercury-brand-color;
    cursor: pointer;
    padding: .25rem 1rem .25rem .25rem;

    //taken from https://davidtang.io/2016/04/07/styling-select-elements-with-css.html
    ///* Removes the default <select> styling */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    ///* Positions background arrow image */
    background-image: url("~icons/triangle@3x.png");
    background-repeat: no-repeat;
    background-position: 100% center;
    background-size: .8rem;

    border-bottom: 1px solid $mercury-accent-color;

    option {
      color: $mercury-brand-color
    }
  }

  &-error {
    margin-top: .5rem;
    color: $mercury-unavailable-color;
  }
}
