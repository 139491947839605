@import "_settings";

.news-grid-item {

  .griditem-options {
    button {
      flex-basis: 33%;
    }

    .button {
      flex-basis: 33%;
    }
  }

}
