@import "_settings";

.autocomplete {
  margin: 0;
  position: relative;

  svg {
    position: absolute;
    right: 1rem;
    top: calc(50% - 9px);
  }
}

.react-autosuggest {
  &__container {
    margin: 0;
    padding: 0;
    position: relative;

    input {
      width: 100%;
    }
  }

  &__suggestions-container {
    margin: 0;
    padding: 0;

    &--open {
      background: $mercury-brand-color;
      color: $white;
      z-index: 1;
      display: flex;
      padding: 1rem;
      width: 100%;
      left: 0;
      top: 100%;
      position: absolute;

      @include when-on(mobile) {
        flex-wrap: wrap;
      }
    }
  }

  &__section-container {
    flex: 1 1 33%;
    margin: 0 0 0 .25%;
    padding: 0;

    @include when-on(mobile) {
      flex: 1 1 48%;
      margin-left: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  &__section-title {
    font-family: $font-emphasis;
    font-size: .825rem;
    font-weight: bold;
    margin: 0;
  }

  &__suggestions-list {
    font-size: .75rem;
    line-height: 1.2;

    li {
      margin-top: .725rem;
      cursor: pointer;

      &:hover {
        color: $mercury-inactive-color-light;
      }
    }
  }
}
