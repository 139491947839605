@import "_settings";

.homepage-story {
  display: block;
  position: relative;
  object-fit: cover;
  width: auto;
  overflow: hidden;
  min-height: 0;
  min-width: 0;
  height: auto;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to bottom, transparent 0%, $mercury-brand-color 95%);
  }

  &:hover {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, transparent 0%, $mercury-brand-color 200%);
    }
  }

  &-title {
    color: $white;
    position: absolute;
    z-index: 1;
    font-weight: bold;
    text-shadow: 0 0 1px rgba($mercury-brand-color, .5);
    left: 1rem;
    width: 90%;

    @include when-on(tablet) {
      left: .5rem;
    }

    &__title:not(.lede-title) {
      font-size: 1.5rem;
      line-height: 125%;
    }
  }

  &-label {
    display: block;
  }
}
