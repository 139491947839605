@import "_settings";

.main-footer {
  display: flex;
  margin-top: 2rem;
  color: $mercury-brand-color;
  font-weight: bold;
  font-size: .875rem;

  @include when-wider-than(tablet) {
    padding: 0 5.25rem 2rem;
  }

  @include when-on(mobile) {
    flex-wrap: wrap;
  }

  &__links {
    display: inline-flex;
    justify-content: space-between;

    @include when-wider-than(tablet) {
      flex-basis: 40%;
    }

    @include when-on(mobile) {
      flex: 1 0 100%;
      padding: 0 5px;
    }

    &-link {
      color: $mercury-brand-color;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__copyright {
    @include when-wider-than(tablet) {
      margin-left: auto;
    }

    @include when-on(mobile) {
      flex: 1 0 100%;
      text-align: center;
      margin-top: 1rem;
    }
  }
}
