@import "_settings";

.unlock {
  width: $bp-mobile;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  @include when-on(mobile) {
    padding: 0 1rem;
    width: $bp-mobile-width;
  }

  p {
    margin-top: 1rem;
    line-height: 1.5;
    font-size: 1.125rem;
  }

  a {
    cursor: pointer;
    border-bottom: 1px dashed $mercury-accent-color;
  }

  &-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .mercury-textinput {
      margin: 1rem 0;
    }
  }

  button {
    float: right;
  }
}
