@import "_settings";

.layout-options {
  display: inline-block;
  margin-left: auto;

  %button {
    width: 15px;
    height: 15px;
    background: $mercury-text-color;
    display: inline-block;
    text-indent: -9999px;

    &:not(.active) {
      background: $mercury-inactive-color;
    }

    &:hover {
      background: $mercury-accent-color;
    }
  }

  %horizontal-bar {
    content: "";
    border-top: 1px solid $white;
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
  }

  &--option-grid {
    @extend %button;
    position: relative;

    &::before {
      content: "";
      border-left: 1px solid $white;
      height: 100%;
      width: 1px;
      position: absolute;
      left: 7px;
    }

    &::after {
      @extend %horizontal-bar;
      bottom: 7px;
    }
  }

  &--option-list {
    @extend %button;
    margin-left: 4px;
    position: relative;

    &::before {
      @extend %horizontal-bar;
      bottom: 4px;
    }

    &::after {
      @extend %horizontal-bar;
      top: 4px;
    }
  }
}
