@import "_settings";

.share-button {
  position: relative;

  @keyframes fade-in-out {
    0% {opacity: 0}
    20% {opacity: 1}
    30% {opacity: 1}
    100% {opacity: 0}
  }

  &-clipboard {
    opacity: 0;
    position: absolute;
    top: -.25rem;
    left: 0;
    z-index: 2;
    color: $white;
    padding: .5rem;
    width: 200px;

    animation-name: fade-in-out;
    animation-duration: 2s;

    background: $mercury-accent-color;
    font-weight: bold;
    text-align: center;
    font-size: .9rem;
  }
}
