@import "_settings";

.radio {
  width: 100%;
  cursor: pointer;
  color: $mercury-accent-color;

  input {
    display: none;
  }

  &-unchecked {
    color: $white;
    opacity: .6;
  }
}
