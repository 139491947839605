@import "_settings";

.asset-not-available {
  width: $bp-mobile;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem 1rem;
  text-align: center;

  @include when-on(mobile) {
    padding: 0 1rem;
    width: $bp-mobile-width;
  }

  p {
    margin: 3rem 3rem 4rem;
    line-height: 1.5;
    font-size: 1.3rem;
  }
}
