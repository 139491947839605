@import "_settings";

.searchbar {
  width: 100%;
  position: relative;
  display: inline-flex;
  margin-right: 1.5rem;
  $search-input-icon-w: 1rem;

  @include when-on(desktop) {
    margin-right: 1.5rem;
  }

  @include when-on(mobile) {
    margin: 0;
  }

  &--quick-find {
    font-family: $font-main;

    button {
      color: $white;
      background-color: $mercury-brand-color;
      height: 100%;
      padding: 0 1rem;
      font-family: $font-main;
      font-weight: bold;
      white-space: nowrap;
    }

    @include when-less-than(desktop) {
      min-width: initial;
    }
  }

  &--searchinput {
    width: 100%;

    form {
      width: 100%;

      .autocomplete {
        width: 100%;

        svg {
          right: 3rem;
        }
      }

      input {
        background-color: $mercury-alt-background-color;
        outline: none;

        @include when-on(desktop) {
          padding: 1rem 1rem 1rem .5 * $search-input-icon-w + 2 * $search-input-icon-w;
        }

        @include when-less-than(desktop) {
          padding: .5rem .5rem .5rem (1rem + $search-input-icon-w);
        }
      }

      .search-input--submit {
        width: initial;
        position: absolute;
      }

      .searchbar-save {
        position: absolute;
        top: 50%;
        right: .5rem;
        transform: translate(0, -50%);
        background: $mercury-accent-color;
        padding: 4px 4px 0;
        cursor: pointer;

        &::before {
          @extend %icon;
          @include convert-icon($white);
          width: 14px;
          background-image: url("~icons/add@3x.png");
        }
      }
    }
  }

  &--quickfind-options {
    top: 100%;
    position: absolute;
    max-width: 100%;
    background-color: $mercury-brand-color;

    &.full-width {
      width: 100%;
    }
  }
}
