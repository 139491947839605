@import "_settings";

.featured-module {
  display: grid;
  margin-bottom: 3rem;

  @include when-on(desktop) {
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      "primary secondary" // sass-lint:disable-line indentation
      "primary tertiary"; // sass-lint:disable-line indentation
    padding-left: 5.25rem;
    padding-right: 8rem;
  }

  &-primary {
    @include when-on(desktop) {
      grid-area: primary;
      font-size: 2vw;
      max-height: 350px;
    }
  }

  &-secondary {
    @include when-on(desktop) {
      grid-area: secondary;
      font-size: 1.25vw;
      max-height: 175px;
    }
  }

  &-tertiary {
    @include when-on(desktop) {
      grid-area: tertiary;
      font-size: 1.25vw;
      max-height: 175px;
    }
  }

  .homepage-story {
    &::after {
      background-image: none;
    }

    &-title {
      bottom: 0;
      padding: 1rem;
      width: 100%;
      background: rgba($mercury-brand-color, .65);
    }
  }

}
