@import "_settings";

.user-preferences {
  width: 80%;
  margin: auto;

  &--footer {
    margin-top: 2rem;

    .button-submit {
      width: 150px;
    }

    &-status {
      margin-left: 1rem;
    }
  }

  &--autorefresh {
    // margin-top: 2rem;

    &-title {
      color: $mercury-brand-color;
      font-family: sans-serif;
      padding: 1rem;
    }

    .accent-toggle {
      margin-left: 1rem;
    }

    &-dropdown {
      margin-top: 1rem;
      margin-bottom: 15rem;
      width: 20%;
      @include when-on(tablet) {
        width: 50%;
      }
      @include when-on(mobile) {
        width: 100%;
      }
    }

    .dropdown-title {
      display: block;
      background-color: $white;
      color: $mercury-brand-color;
      text-align: left;

      &--icon {
        @include convert-icon($mercury-brand-color);
        transform: rotate(45deg);
      }
    }

    li {
      background-color: $mercury-brand-color;
      color: $white;

      &:hover {
        background-color: $mercury-text-color;
        color: $mercury-brand-color;
      }
    }
  }
}
