@import "_settings";

.video-series-content {
  max-width: 1300px;
  margin: auto;

  .carousel-status {
    display: none;
  }

  .series-catalog-list--item-title,
  .series-catalog-list--item-summary {
    height: 4rem;
  }

  @include when-on(mobile) {
    .series-catalog-list--item-title,
    .series-catalog-list--item-summary {
      height: initial;
    }
  }

  @include when-on(tablet) {
    width: 85%;
  }

  @include when-on(desktop) {
    width: 80%;
  }
}
