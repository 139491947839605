@import "_settings";

.navigation-bar {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-areas: "leftside center rightside";
  position: sticky;
  z-index: 1;
  background: $white;

  @include when-on(desktop) {
    padding: 1rem 0;
    top: $header-height-desktop;
  }

  @include when-less-than(desktop) {
    padding: 1rem;
  }

  @include when-on(tablet) {
    top: $header-height-tablet;
  }

  @include when-on(mobile) {
    top: calc(#{$header-height-mobile} + 10px);
  }


  &--left {
    grid-area: leftside;
  }

  &--center {
    grid-area: center;
  }

  &--right {
    grid-area: rightside;
    justify-self: end;

    @include when-on(mobile) {
      text-align: right;
    }
  }

  %navigation-bar-button {
    background-color: transparent;
    margin-left: 1rem;
    font-family: $font-main;
    color: $mercury-brand-color;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      color: $mercury-accent-color;
    }
  }

  %navigation-bar-asset-button {
    @extend %navigation-bar-button;
    color: $mercury-brand-color;
    font-size: 1.125rem;
  }

  .button {

    &--manage-collection {
      @extend %navigation-bar-button;

      &::before {
        @extend %icon;
        @include convert-icon($mercury-accent-color);
        background-image: url("~icons/ICON_3x_manage.png");
        margin-right: 4px;
      }
    }

    &--search {
      @extend %navigation-bar-button;

      &::before {
        @extend %icon;
        @include convert-icon($mercury-accent-color);
        background-image: url("~icons/back-close@3x.png");
        margin-right: 4px;
      }
    }

    &--my-collections {
      @extend %navigation-bar-button;

      &::before {
        @extend %icon;
        @include convert-icon($mercury-accent-color);
        background-image: url("~icons/collection-folder@3x.png");
        margin-right: 4px;
      }
    }

    &--add-to-collection {
      @extend %navigation-bar-button;
      font-family: $font-main;

      &:disabled {
        color: $mercury-inactive-color;
        background: transparent;
      }

      &::before {
        @extend %icon;
        background-image: url("~icons/ICON_3x_add_remove_selected.png");
        margin-right: 4px;
      }
    }

    &--remove-from-collection {
      @extend %navigation-bar-button;

      &:disabled {
        color: $mercury-inactive-color;
        background: transparent;
      }

      &::before {
        @extend %icon;
        background-image: url("~icons/YELLOW_REMOVE_ITEMS@3x.png");
        margin-right: 4px;
        height: 16px;
      }
    }

    &--share-collection {
      @extend %navigation-bar-button;

      &::before {
        @extend %icon;
        background-image: url("~icons/COLLECTION_CONTROLS@3x_SHARE.png");
        margin-right: 4px;
      }
    }

    &--share-asset {
      @extend %navigation-bar-asset-button;

      &::after {
        @extend %icon;
        @include convert-icon($mercury-accent-color);
        background-image: url("~icons/share@3x.png");
        margin-left: 1rem;
      }
    }

    &--comp-asset {
      @extend %navigation-bar-asset-button;

      &::after {
        @extend %icon;
        @include convert-icon($mercury-accent-color);
        background-image: url("~icons/download-comp@3x.png");
        margin-left: 1rem;
      }
    }

    &--add-asset {
      @extend %navigation-bar-asset-button;

      &::after {
        @extend %icon;
        @include convert-icon($mercury-accent-color);
        background-image: url("~icons/add@3x.png");
        margin-left: 1rem;
      }
    }

    &--download-asset {
      @extend %navigation-bar-asset-button;

      &::after {
        @extend %icon;
        @include convert-icon($mercury-accent-color);
        background-image: url("~icons/download-comp@3x.png");
        margin-left: 1rem;
      }
    }

    &--download-collection {
      @extend %navigation-bar-button;

      &::before {
        @extend %icon;
        background-image: url("~icons/COLLECTION_CONTROLS@3x_DOWNLOAD.png");
        margin-right: 4px;
      }
    }

    &--delete-collection {
      @extend %navigation-bar-button;

      &::before {
        @extend %icon;
        background-image: url("~icons/COLLECTION_CONTROLS@3x_DELETE.png");
        margin-right: 4px;
      }
    }
  }
}
