@import "_settings";

.search-nav {

  &-filters {
    display: inline-flex;

    @include when-on(mobile) {
      height: 100%;
    }

    button {
      font-size: 1rem;
      font-family: $font-main;
      color: $mercury-brand-color;
      background: transparent;
      align-self: center;

      &::before {
        @extend %icon;
        @include convert-icon($mercury-accent-color);
        background-image: url("~icons/filter@3x.png");
        margin-right: 4px;
      }

      &:hover {
        color: $mercury-accent-color;
      }

      @include when-on(mobile) {
        font-size: 0;

        &::before {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }

  &-collections {
    margin-left: 1rem;
    font-size: 1rem;
    font-family: $font-main;
    align-self: center;
    display: inline-block;

    @include when-on(mobile) {
      font-size: 0;
      height: 100%;

      .button--add-to-collection::before,
      .button--my-collections::before {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  &-results {
    margin-top: 2px;
    color: $mercury-brand-color;
    font-size: .75rem;
  }
}
