@import "_settings";

@mixin expand-accordion {
  max-height: 100vh;
  overflow-y: visible;
}

@mixin collapse-accordion {
  max-height: 0;
  overflow-y: hidden;
}

@keyframes expand {
  0% { @include collapse-accordion }
  100% { @include expand-accordion }
}

@keyframes collapse {
  0% { @include expand-accordion }
  100% { @include collapse-accordion }
}

.accordion {
  &.expand {
    summary {
      &::after {
        content: "-";
      }
    }

    &.animate > .slide-container {
      animation: expand .2s forwards;
    }

    &:not(.animate) > .slide-container {
      @include expand-accordion;
    }
  }

  &.collapse {
    &.animate > .slide-container {
      animation: collapse .2s forwards;
    }

    &:not(.animate) > .slide-container {
      @include collapse-accordion;
    }
  }

  summary {
    position: relative;
    width: 100%;
    outline: none;
    cursor: pointer;

    &::-webkit-details-marker {
      display: none;
    }

    &::after {
      content: "+";
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 100%;
      font-size: 1.75rem;
      font-family: $font-main;
      color: $mercury-accent-color;
    }
  }

  section {
    margin: 0;
    padding: .1rem 3rem;

    @include when-on(tablet) {
      padding: .1rem 2rem;
    }
  }
}
