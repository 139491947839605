@import "_settings";

.homepage {
  &-container {
    margin-bottom: 3rem;

    @include when-on(desktop) {
      padding-left: 5.25rem;
      padding-right: 8rem;
    }
  }
}
