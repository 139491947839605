@import "_settings";

.multifile-download {
  max-height: 80vh;
  overflow-y: scroll;

  @include when-on(desktop) {
    width: $bp-desktop * .8;
  }

  @include when-on(tablet) {
    width: 80%;
  }

  @include when-on(mobile) {
    width: 100%;
  }

  h3 {
    color: $mercury-accent-color;
    font-size: .875rem;
    text-align: center;
    margin-bottom: 10vh;
  }

  &--files {
    padding: 0 2rem;
  }

  &--file {
    width: 100%;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 1.25fr 50px .9fr 1fr;
    grid-column-gap: 1rem;
    grid-template-areas: "asset remove options status";
    justify-items: start;
    align-items: center;
    text-align: left;

    &.legend {
      padding-bottom: 3rem;
      color: $white;
      font-size: 1.125rem;
      font-weight: bold;
    }

    &__asset {
      grid-area: asset;
      color: $white;
      line-height: 1;
      display: inline-flex;
      align-items: center;

      figure {
        display: inline-block;
        margin-right: .5rem;
      }
    }

    &__options {
      position: relative;
      grid-area: options;
      width: 80%;

      select {
        /* General styling */
        border-radius: 0;
        border: 0;
        width: 100%;
        background: transparent;
        color: $white;
        cursor: pointer;
        padding-right: 1rem;

        //taken from https://davidtang.io/2016/04/07/styling-select-elements-with-css.html
        ///* Removes the default <select> styling */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        ///* Positions background arrow image */
        background-image: url("~icons/triangle@3x.png");
        background-repeat: no-repeat;
        background-position: 100% center;
        background-size: .8rem;

        option {
          color: $mercury-brand-color
        }
      }

      .toggle-container {
        position: absolute;
        left: 82%;
        bottom: 0;
        width: 100%;

        &-copy {
          font-size: 12px;
          padding-bottom: .5rem;
          color: $mercury-inactive-color-light;
        }
      }
    }

    &__remove {
      @extend %icon;
      grid-area: remove;

      &::before {
        @extend %icon;
        background-image: url("~icons/COLLECTION_CONTROLS@3x_DELETE.png");
      }
    }

    &__status {
      grid-area: status;
      justify-self: center;

      svg {
        height: 1rem;
        fill: $mercury-accent-color;
      }
    }
  }

  &--actions {
    margin-top: 2vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: .5rem 0 1rem;

    button {
      width: 200px;
      text-align: center;
      font-size: 1.375rem;

      @include  when-on(mobile) {
        width: 150px;
        font-size: 1.25rem;
      }
    }

    &__cancel {
      @extend %button-base;
      border-color: $mercury-accent-color;

      &:hover {
        background: $mercury-text-color;
      }
    }
  }
}
