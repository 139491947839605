@import "_settings";

.shareitem-modal {
  padding: 1rem 2rem 4rem;
  font-size: 1.125rem;
  color: $white;

  @include when-on(desktop) {
    width: $bp-mobile;
  }

  @include when-less-than(desktop) {
    width: 100%;
  }

  h4 {
    color: $white;
    text-align: center;
  }

  .email-data-tag {
    display: inline-block;
    margin: .1rem .15rem;
    padding: .4rem;
    color: $mercury-brand-color;
    border: 1px solid $mercury-accent-color;
    border-radius: .25rem;
    background-color: $mercury-alt-background-color;

    span {
      margin-left: .65rem;
      cursor: pointer;
    }
  }

  &--form {
    margin-top: 1rem;

    &--multi-email {
      margin: 0;

      .placeholder {
        color: $mercury-text-color;
      }

      & label > span {
        display: none;
      }
    }
  }

  &--mailstatus {
    background-color: $mercury-inactive-color-light;
    padding: 1rem;
    border-radius: .5rem;
    color: $mercury-brand-color;
    justify-content: center;
    font-size: 1rem;

    ul {
      margin-top: 1rem;
      font-size: .8rem;
    }
  }

  .button-contact {
    width: 10rem;
    color: $mercury-brand-color;
  }
}
