@import "_settings";

.manage-collections-modal {
  padding: 1rem 4rem 4rem;
  font-size: 1.125rem;

  h4 {
    color: $mercury-accent-color;
    text-align: center;
  }

  %manage-collections-modal__section {
    margin-bottom: 3rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $mercury-accent-color;
  }

  &--basics {
    @extend %manage-collections-modal__section;
    margin-top: 4rem;

    .mercury-textinput {
      display: flex;
      margin-bottom: .5rem;

      label {
        color: $white;
        font-weight: bold;
        flex-basis: auto;
        align-self: center;
        margin: 0 2px 0 0;
      }

      input {
        flex-grow: 1;
        background: lighten($mercury-brand-color, 14%);
        color: $white;
        border: 0;
      }

    }

    &__name {
      display: block;
      margin-bottom: .5rem;
      font-weight: bold;
    }

  }

  &--admin {
    @extend %manage-collections-modal__section;

    .custom-checkbox-label {
      margin-left: 1rem;
    }

    &__expirations {
      margin-top: .5rem;
      display: flex;
      height: 40px;
    }

    &__expires {
      display: inline-flex;

      span {
        font-size: .875rem;
        align-self: center;
        margin-left: 1rem;
      }
    }

    &__toggle {
      align-self: center;

      &.react-toggle--checked {
        align-self: center;

        &:hover:not(.react-toggle--disabled) {
          .react-toggle-track {
            background-color: $mercury-accent-color-light;
          }
        }

        .react-toggle-track {
          background-color: $mercury-accent-color;
        }

        .react-toggle-thumb {
          border: 1px solid $white;
        }
      }
    }

    &__expires-at {
      margin-left: 3rem;
      align-self: center;

      button {
        border: 1px solid $mercury-accent-color;
        border-radius: 40px;
        width: 40px;
        height: 40px;
        background: transparent;
        color: $white;
      }

      span {
        margin: 0 1rem;
      }
    }
  }

  &--copy-link {
    display: flex;

    label {
      align-self: center;
    }

    span {
      margin-left: .5rem;
      flex-basis: 50%;
      color: $mercury-accent-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      align-self: center;
    }

    button {
      margin-left: .5rem;
      border-radius: 8px;
      border: 1px solid $white;
      padding: 2px 1rem;
      background: transparent;
      color: $mercury-accent-color;
      align-self: center;

      &:hover {
        background: $mercury-accent-color;
        color: $white;
      }
    }
  }

  &--actions {
    margin-top: 4rem;
    display: flex;
    justify-content: space-evenly;
    padding: 0 2rem;

    button {
      color: $mercury-brand-color;
    }
  }
}
