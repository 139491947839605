@import "_settings";

.mercury-textareainput {

  label {
    display: block;
    color: $mercury-brand-color;
    margin-bottom: .5rem;
  }

  textarea {
    width: 100%;
    border: 3px solid $mercury-accent-color;
    padding: .25rem;

    &::placeholder {
      color: $mercury-inactive-color;
    }
  }

  &-error {
    margin-top: .5rem;
    color: $mercury-unavailable-color;
  }
}
