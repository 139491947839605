@import "_settings";

.filters {
  background-color: $mercury-filter-bg-color;
  padding: 0;
  overflow: hidden;
  height: calc(100vh - #{$header-height-desktop} - 2rem);
  position: relative;

  .back-button {
    position: absolute;
    top: 1.75rem;
    right: 1.5rem;
    background: url("~icons/back-close@3x.png") no-repeat;
    background-size: contain;
    width: 1.5rem;
    height: 1.5rem;
    filter: invert(100%);
  }

  &--scrollable {
    h2 {
      color: $white;
      padding: 2rem 3rem 1rem;
      font-family: $font-accent;
    }

    hr {
      margin: 0;
      border: .5px solid $mercury-accent-color;
    }

    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - #{$header-height-desktop} - 6rem);
  }

  &.filter-hide {
    transform: translate(-100%);
  }

  &--section {
    position: relative;
    margin-top: 1rem;
    padding: 0 3rem 1rem;

    legend {
      font-size: 1.125rem;
      font-family: $font-main;
      color: $mercury-inactive-color;
      margin-bottom: .75rem;

      &:first-letter {
        text-transform: capitalize;
      }

      &::before {
        @extend %icon;
        position: absolute;
        top: -.2rem;
        left: 1rem;
        height: 100%;
      }
    }

    &.media-filters > legend::before {
      background-image: url("~icons/filter.svg");
    }
  }

  &--group {
    display: flex;
    flex-direction: column;

    &.closed {
      display: none;
    }

    summary {
      color: $mercury-inactive-color;
      font-family: $font-main;
      font-size: 1.125rem;
      padding: 1rem 3rem;

      &::before {
        @extend %icon;
        position: absolute;
        top: .8rem;
        left: 1rem;
        height: 100%;
      }
    }

    &.search-terms > summary::before {
      background-image: url("~icons/B_search@3x.png");
    }

    &.news > summary::before {
      top: 1rem;
      background-image: url("~icons/ARTICLE_icon-ORG@3x.png");
    }

    &.photos > summary::before {
      top: 1rem;
      background-image: url("~icons/photo_ONLY-ORG@3x.png");
    }

    &.video > summary::before {
      background-image: url("~icons/video_only_ORG@3x.png");
    }

    > section {
      margin-top: 1rem;
      padding: 0 3rem 1rem;
    }
  }

  &--checkboxes {
    label {
      display: block;
      padding-bottom: .5rem;
      color: $white;

      &:hover {
        color: $mercury-accent-color;
      }
    }

    legend {
      padding: .5rem 0;
      font-size: .75rem;
      color: $white;

      &:first-letter {
        text-transform: capitalize;
      }
    }
  }

  &--dates {
    display: flex;
    padding-right: 1rem;
    padding-bottom: 1rem;
    justify-content: space-between;
  }

  &--date {
    flex-basis: 40%;

    legend {
      color: $white;
      font-size: .875rem;
      font-family: $font-emphasis;
    }
  }

  &--keyword {
    position: relative;

    &__input {
      margin-top: 0;

      input {
        padding: 0;
      }
    }

    &__clear {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &--actions {
    position: absolute;
    bottom: 0;
    width: 100%;

    &-fade {
      height: 2rem;
      background-image: linear-gradient(0deg, $mercury-filter-bg-color 0%, $mercury-filter-bg-color 25%, $mercury-filter-bg-color 60%, transparent 100%);
    }

    &--buttons {
      width: 100%;
      display: flex;
      justify-content: space-around;
      padding-bottom: 1rem;
      background-color: $mercury-filter-bg-color;

      button {
        @extend %button-base;
        width: 40%;
        border-color: $mercury-accent-color;

        @include when-less-than(desktop) {
          width: 45%;
        }

        &[type=reset] {
          border-color: $mercury-text-color;
        }

        &:hover {
          background-color: transparent;
          border-color: $mercury-brand-color;
          color: darken($white, 10%);
        }
      }
    }
  }
}
