@import "_settings";

.related-photos {
  grid-area: relatedImages;
  margin-top: 1rem;

  @include when-less-than(desktop) {
    padding: 1rem;
  }

  &-container {
    display: flex;
    flex-wrap: wrap;
  }

  &-img {
    margin-top: 1rem;
    padding-right: .5rem;

    @include when-on(mobile) {
      flex-basis: 50%;
    }
  }

  %icon {
    @include convert-icon($mercury-accent-color);
    content: "";
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    width: .8rem;
    height: .8rem;
    margin-left: .5rem;

    &:hover {
      @include convert-icon($mercury-text-color);
    }
  }

  &-findall {
    align-self: center;
    color: $mercury-brand-color;
    background: transparent;
    font-size: 1.25rem;
    padding-top: 1rem;

    &::after {
      @extend %icon;
      transform: rotate(-90deg);
      background-image: url("~icons/download-comp@3x.png");
    }

    &:hover {
      color: $mercury-text-color;
    }
  }
}
