$breakpoints: (
  mobile: 0,
  tablet: $bp-tablet,
  desktop: $bp-desktop
);

$there-is-no-higher-breakpoint: 9999em;

$allowed-breakpoint-targets: inspect(map-keys($breakpoints));

@function up-to($target) {
  @return $target - 1px;
}

@function get-breakpoint($target) {
  $breakpoint: map-get($breakpoints, $target);

  @if $breakpoint == null {
    @warn("You tried to respond_to '#{$target}'. Please use one of these available breakpoints - #{$allowed-breakpoint-targets}");
  }

  @return $breakpoint;
}

@function get-next-breakpoint($target) {
  $possible-nexts: ();
  $next-breakpoint: null;

  @each $breakpoint, $value in $breakpoints {
    @if $breakpoint == $target or length($possible-nexts) > 0 {
      $possible-nexts: append($possible-nexts, $breakpoint);
    }
  }

  @if length($possible-nexts) > 1 {
    $next-breakpoint: nth($possible-nexts, 2);
    @return up-to(map-get($breakpoints, $next-breakpoint));
  }

  @return $there-is-no-higher-breakpoint;
}

@mixin when-wider-than($target) {

  @media screen and (min-width: get-breakpoint($target)) {
    @content;
  }

}

@mixin when-less-than($target) {

  @media screen and (max-width: up-to(get-breakpoint($target))) {
    @content;
  }

}

@mixin when-on($target) {

  @media screen and (min-width: get-breakpoint($target)) and (max-width: get-next-breakpoint($target)) {
    @content;
  }

}

@mixin when-on-retina($target) {

  @media
  screen and (min-width: get-breakpoint($target)) and (max-width: get-next-breakpoint($target))
  and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }

}
