@import "_settings";

@mixin contributor-circle($circle-radius, $circle-border-width) {
  max-width: $circle-radius * 2;

  &-abbv {
    width: $circle-radius * 2;
    height: $circle-radius * 2;
    color: $mercury-brand-color;

    &::before {
      top: $circle-border-width / -2;
      left: $circle-border-width / -2;
      width: $circle-radius * 2 + $circle-border-width;
      height: $circle-radius * 2 + $circle-border-width;
    }
  }
}

.contributors-contributor {
  display: inline-block;
  text-align: center;

  @include when-on(desktop) {
    @include contributor-circle(50px, 16px);
  }

  @include when-less-than(desktop) {
    @include contributor-circle(37px, 14px);
  }

  @include when-on(mobile) {
    flex-basis: 33%;
    max-width: 33%;
    margin-bottom: 1rem;
  }

  &-abbv {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: $white;
    color: $mercury-brand-color;
    font-weight: bold;
    text-transform: uppercase;

    @include when-on(desktop) {
      font-size: 2.5rem;
    }

    @include when-less-than(desktop) {
      font-size: 1.75rem;
    }

    &::before {
      content: "";
      position: absolute;
      display: block;
      border-radius: 100%;
      z-index: -1;
      background: linear-gradient(to bottom, $mercury-accent-color-light, $mercury-accent-color-dark);
    }
  }

  &-title {
    display: inline-block;
    color: $mercury-brand-color;
    margin-top: .85rem;

    @include when-on(desktop) {
      font-size: 1.125rem;
      line-height: 1.33;
    }

    @include when-less-than(desktop) {
      font-size: .875rem;
      line-height: 1.14;
    }

    @include when-on(mobile) {
      width: 100%;
    }
  }
}
