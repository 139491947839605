@import "_settings";

.forgot-password {
  width: $bp-mobile;
  margin: 0 auto;

  @include when-on(mobile) {
    padding: 0 1rem;
  }

  &-form {
    margin-top: 4rem;

    button {
      margin-top: 1rem;
      float: right;
    }
  }
}
