@import "_settings";

.lede {
  .topic-story {
    margin-bottom: 3rem;

    .ri-image {
      @include when-on(desktop) {
        object-fit: cover;
        height: 500px;
      }
    }

    &-title {
      font-family: $font-accent;
      width: 100%;
      left: 0;

      @include when-on(desktop) {
        font-size: 3.75rem;
        bottom: 2rem;
      }

      @include when-on(tablet) {
        font-size: 3rem;
        bottom: .5rem;
      }

      @include when-on(mobile) {
        font-size: 1.125rem;
        bottom: 1.125rem;
      }

      span {
        display: block;
        width: $bp-desktop-width;
        margin: 0 auto;
        padding: 0 calc(.5rem + 78px);

        @include when-on(tablet) {
          width: $bp-tablet-width;
          padding: 0 78px;
        }

        @include when-less-than(tablet) {
          width: $bp-mobile-width;
          padding: 0 1rem;
          font-size: 1.4rem;
        }
      }
    }

    &-label {
      @include when-on(desktop) {
        font-size: 1.5rem;
        font-family: $font-main;
      }

      @include when-less-than(desktop) {
        font-size: 1rem;
      }
    }
  }
}
