@import "_settings";

.announcement {
  color: $white;
  position: relative;
  z-index: 1;
  height: 100%;
  margin-top: 4rem;
  margin-bottom: min(-5.1rem, -4.2rem);

  @include when-on(mobile) {
    margin-top: 6rem;
    margin-bottom: -6.35rem;
  }

  @include when-on(tablet) {
    margin-bottom: -4rem;
  }

  &__wrapper {
    background-color: $mercury-alt-dark-background-color;
    display: flex;
    width: 100%;
    justify-content: center;
    
    @include when-on(mobile) {
      flex-direction: column;
    }
  }


  &.closed {
    display: none;
    margin-bottom: 0;
  }

  &__close {
    position: absolute;
    top: 3px;
    right: 0;
    background: transparent;

    &::before {
      @extend %icon;
      width: 2.8rem;
      height: 2.5rem;
      background-image: url("~icons/B_close@3x.png");
    }
  }

  &__summary {
    color: $white;
    display: inline-block;
    font-size: 1.3rem;
    justify-self: center;
    align-self: center;
    line-height: 30px;
    text-align: center;
    padding: .8rem;
    width: auto;
  }

  &__detail {
    color: $white;
    position: relative;
    margin-top: 1rem;
    text-decoration: underline;
    font-size: .8rem;
    align-self: center;
    padding-bottom: .8rem;

    @include when-on(mobile) {
      margin-top: 0
    }

    &.closed {
      display: none;
      margin-bottom: 0;
    }
  }
}
