@import "_settings";

%button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $mercury-brand-color;
  background-color: transparent;
  font-family: $font-emphasis;
  border-radius: 7px;
  font-size: .875rem;
  font-weight: bold;
  width: 85px;
  height: 31px;

  &:hover {
    background-color: $mercury-alt-background-color;
  }
}

.user-actions {
  margin-left: auto;

  @include when-on(tablet) {
    flex: 1 0 100%;
  }

  @include when-on(mobile) {
    flex-grow: 1;
    display: inline-flex;
    justify-content: space-between;

    .dropdown-container {
      min-width: 6rem;
    }

    .dropdown-title,
    .dropdown-list li {
      padding: 1rem .5rem;
    }


    .dropdown-user--icon {
      span {
        display: none;
      }

      &::before {
        height: 23px;
        width: 23px;
        @include convert-icon($mercury-brand-color);
        background-image: url("~icons/ic-account-circle@3x.png");

        // copied from %icon because of error when using @extend here:
        // You may not @extend an outer selector from within @media.
        content: "";
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: transparent;
      }
    }
  }

  &__sign-in {
    @extend %button;
    background-color: $mercury-accent-color;
    border: 1px solid $mercury-accent-color;
    margin: 0 1rem 0 .5rem;

    @include when-on(mobile) {
      margin: .5rem .5rem 0 0;
    }
  }

  &__register {
    @extend %button;
    border: 1px solid $mercury-brand-color;

    @include when-on(mobile) {
      margin-top: .5rem;
    }
  }
}
