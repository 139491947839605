@import "_settings";

.item {
  position: relative;

  &--list {
    width: 100%;
    padding: 1rem 1rem 1rem 0;
    display: grid;
    grid-column-gap: .75rem;
    border-bottom: 1px solid $mercury-inactive-color-light;
    grid-template-columns: 8rem 1.5rem 6rem 2rem auto;
    // sass-lint:disable-block indentation
    grid-template-areas:
            "image icon extrainfo wire options"
            "image title title title title"
            "image summary summary summary summary";

    @include when-on(mobile) {
      padding: 1rem 0;
      grid-template-columns: 3rem auto auto;
      // sass-lint:disable-block indentation
      grid-template-areas:
            "icon wire extrainfo"
            "image image title"
            "image image summary"
            "options options options";
    }

    &:hover {
      border-bottom: 1px solid $mercury-accent-color;

      @include when-wider-than(tablet) {
        .item--list--options {
          display: initial;
        }
      }
    }

    &--icon {
      grid-area: icon;

      &::before {
        @extend %icon;
        background-position: center;
        width: 20px;
        height: 20px;
        filter: grayscale(100%) invert(100%);
      }

      &.image::before {
        background-image: url("~icons/photo_ONLY-ORG@3x.png");
      }

      &.news::before {
        background-image: url("~icons/ARTICLE_icon-ORG@3x.png");
      }

      &.video::before {
        background-image: url("~icons/video_only_ORG@3x.png");
      }

      &.collection::before {
        background-image: url("~icons/Collections@3x.png");
      }
    }

    &--image {
      grid-area: image;
      min-height: 140px;

      @include when-on(mobile) {
        min-height: auto;
        max-width: 100px;
        padding-top: .5rem;
      }
    }

    &--extra-info {
      grid-area: extrainfo;
      color: $mercury-brand-color;
      font-family: $font-accent;
      font-size: 10px;

      display: inline-flex;
      flex-direction: column;

      time {
        margin-bottom: 2px;
      }
    }

    &--wire {
      grid-area: wire;
      color: $mercury-brand-color;
      font-family: $font-accent;
      font-weight: bold;
      font-size: .75rem;

      @include when-on(mobile) {
        justify-self: end;
        align-self: center;
        margin-right: 1rem;
      }
    }

    &--options {
      grid-area: options;
      padding-left: 3rem;
      display: none;

      @include when-on(mobile) {
        padding-left: 0;
        padding-top: 1rem;
        display: flex;
        justify-content: space-between;
      }

      & > button,
      .button > button {
        display: inline-flex;
        align-items: center;
        font-size: .9rem;
        font-family: $font-emphasis;
        color: $mercury-brand-color;
        background-color: transparent;
        margin-right: 1.5rem;

        @include when-on(mobile) {
          margin-right: 0;
        }

        &::before {
          @extend %icon;
          background-position: center;
          width: 20px;
          height: 20px;
          margin-right: .4rem;
        }

        &.button {
          &--share::before {
            background-image: url("~icons/COLLECTION_CONTROLS@3x_SHARE.png");
          }

          &--add::before {
            background-image: url("~icons/B_add@3x.png");
          }

          &--add-added::before {
            background-image: url("~icons/ICON_3x_selected_asset.png");
          }

          &.asset-not-added::before {
            background-image: url("~icons/YELLOW_REMOVE_ITEMS@3x.png");
          }

          &--download {
            color: $mercury-brand-color;

            &::before {
              background-image: url("~icons/COLLECTION_CONTROLS@3x_DOWNLOAD.png");
            }
          }
        }
      }

      .story-download-options {
        background-color: $mercury-brand-color;

        &-button {
          color: $white;

          &:hover {
            color: $mercury-accent-color;
          }
        }

        &::before {
          border-left: 10px solid $mercury-brand-color;
        }
      }
    }

    &--title {
      grid-area: title;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: $font-emphasis;
      font-size: 1.5rem;
      font-weight: bold;

      @include when-on(mobile) {
        font-size: 1rem;
        padding: .5rem 0;
        white-space: normal;
      }

      &:hover {
        color: $mercury-accent-color;
      }
    }

    &--summary {
      grid-area: summary;

      @include when-on(mobile) {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
  }
}
