@import "_settings";

.collections-modal {
  padding: 1rem 2rem 4rem;
  font-size: 1.125rem;

  @include when-on(desktop) {
    width: $bp-mobile;
  }

  @include when-less-than(desktop) {
    width: 100%;
  }

  h4 {
    color: $white;
    text-align: center;
  }

  &--filter {
    margin-top: 2.75rem;

    &--query {
      input {
        background: $mercury-text-color;
        color: $white;
      }
    }
  }

  &-error {
    color: $mercury-accent-color-dark;
    margin-top: 1rem;
  }

  &-add-new {
    margin-top: 1.5rem;
    background: transparent;
    color: $white;
    font-family: $font-main;
    text-align: left;

    &::before {
      @include convert-icon($mercury-accent-color);
      content: "";
      display: inline-block;
      background-image: url("~icons/add@3x.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 14px;
      height: 14px;
      margin-right: 2px;
      margin-top: 4px;
    }
  }

  &-add-to {
    background: transparent;
    color: $white;
    font-family: $font-main;
    margin-top: 1rem;

    &::before {
      content: "";
      display: inline-block;
      background-image: url("~icons/Collections@3x.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 1rem;
      height: 1rem;
      margin-right: 1rem;
      margin-bottom: -4px;
    }

    &::after {
      content: ">";
      display: inline-block;
      color: $mercury-accent-color;
      font-size: 1rem;
      margin-left: 4px;
    }
  }

  ul {
    margin-top: 1.5rem;
    height: 50vh;
    overflow-y: auto;
  }
}
