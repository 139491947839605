@import "_settings";

.video-detail {
  display: grid;

  @include when-on(desktop) {
    width: $bp-desktop-width;
    margin: 0 auto;
    grid-template-columns: 1000px auto;
    grid-template-rows: auto auto 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-areas: "video info" "commands info" "relatedImages info";
  }

  @include when-less-than(desktop) {
    grid-template-areas: "video" "commands" "info" "relatedImages";
  }

  &-info {
    grid-area: info;

    @include when-less-than(desktop) {
      margin-top: 2rem;
      padding: 0 1rem;
    }

    @include when-on(tablet) {
      display: inline-grid;
      grid-template-columns: 70% 30%;
      grid-column-gap: 1rem;
      grid-template-areas: "title ." "caption keywords" "caption information";
    }

    h1 {
      margin: 0;
      color: $mercury-text-color;

      @include when-on(tablet) {
        grid-area: title;
      }
    }

    h4 {
      font-size: 1.125rem;
      color: $mercury-text-color;
      margin-bottom: .5rem;
    }

    figcaption {
      margin-top: .5rem;
      line-height: 1.1;

      @include when-on(tablet) {
        grid-area: caption;
        align-self: stretch;
      }
    }

    &-categorization {
      @include when-on(tablet) {
        grid-area: keywords;
        margin-top: 0;

        > div:first-child {
          margin-top: 0;
        }
      }

      > div {
        margin-top: 1rem;
      }

      h4 {
        text-transform: capitalize;
      }
    }

    &-keyword {
      color: $mercury-accent-color;
      margin-right: .75rem;
      font-size: .875rem;
      font-weight: bold;
      font-family: $font-main;
      display: inline-block;
    }

    &-information {
      margin-top: 1rem;

      @include when-on(tablet) {
        grid-area: information;
      }

      span {
        display: block;
        margin-bottom: 5px;
      }
    }
  }

  &-commands {
    margin-top: 1rem;
    grid-area: commands;

    @include when-on(desktop) {
      padding-bottom: 5rem;
    }

    @include when-less-than(desktop) {
      padding: 0 1rem;
    }

    .navigation-bar--left,
    .navigation-bar--right {
      margin: auto 0;
    }

    .navigation-bar--center {
      display: inline-flex;
      justify-content: space-between;
      align-items: flex-start;

      .dropdown-container {
        min-width: 12rem;

        @include when-on(mobile) {
          min-width: 10rem;
        }

        &.open button {
          color: $white;
          background-color: $mercury-brand-color;
        }

        .dropdown-title {
          justify-content: left;
        }

        .dropdown-title--icon {
          transition: none;
          transform: rotate(45deg);
          @include convert-icon($mercury-accent-color);
        }

        .dropdown-list {
          ul {
            background-color: $mercury-brand-color;

            li {
              padding: 0;

              a {
                display: block;
                width: 100%;
                padding: 1rem;
                text-align: left;
                color: $white;

                &:hover {
                  color: $mercury-accent-color;
                }

                &::after {
                  margin: 0;
                  background-image: none;
                }
              }

              button {
                display: block;
                width: 100%;
                padding: 1rem;
                text-align: left;
                color: $white;

                &:hover {
                  color: $mercury-accent-color;
                }

                &::after {
                  margin: 0;
                  background-image: none;
                }
              }

              span {
                display: block;
                width: 100%;
                padding: 1rem;
                text-align: left;
                color: $mercury-inactive-color;
              }
            }
          }
        }
      }
    }

    svg {
      display: inline-block;
      fill: $mercury-accent-color;
      width: 40px;
      height: 12px;
    }
  }
}
