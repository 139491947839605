@import "_settings";

.clear-all-filters {
  margin-top: 1.125rem;

  .click-container {
    background-color: transparent;
    display: flex;

    .clear-filters-button {
      margin-right: 7px;
      padding: .1rem 0 0;
    }

    .clear-filters-label {
      color: $white;
      cursor: pointer;
      font-family: $font-main;
      position: relative;
      bottom: .08rem;
    }
  }
}
