@import "_settings";

.collections-collection {
  padding: 0;

  @include when-on(desktop) {
    min-width: 250px;
    min-height: 250px;
  }

  %text-format {
    color: $white;
    text-shadow: 1px 1px 2px rgba($mercury-brand-color, .5);
    font-family: $font-main;
  }

  &--footer {
    @include when-on(desktop) {
      transition: all 300ms ease-in-out;
      transform: translate(0, 50%);
    }
  }

  &--count {
    @extend %text-format;
    font-size: 1.125rem;
    position: absolute;
    top: 12px;
    left: 12px;

  }

  &--createdat {
    @extend %text-format;
    font-size: .55rem;
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translate(-50%);

    &::before {
      content: "Created:";
      margin-right: 4px;
    }
  }

  &--icon {
    &::before {
      position: absolute;
      top: 12px;
      right: 12px;
      content: "";
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      width: 15px;
      height: 15px;
      fill: $white;
    }

    &.folder {
      &::before {
        @include convert-icon($white);
        background-image: url("~icons/collection-folder@3x.png");
      }
    }

    &.shared {
      &::before {
        background-image: url("~icons/sharedGroup.png");
      }
    }
  }

  &--thumb {
    background: $mercury-brand-color;
    width: 100%;
    height: 100%;
    display: block;
  }

  .griditem-title {
    font-size: 1.5rem;
    font-family: $font-emphasis;
  }

  .griditem-options {
    button {
      flex-basis: 20%;
      margin-left: 1%;
      font-size: .75rem;
    }
  }
}
