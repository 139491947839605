@import "_settings";

.signin {
  width: $bp-mobile;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @include when-on(mobile) {
    padding: 0 1rem;
    width: $bp-mobile-width;
  }

  p {
    margin-top: 1rem;
    line-height: 1.5;
    font-size: 1.125rem;
  }

  &-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .mercury-textinput {
      margin: 1rem 0;
    }
  }

  button {
    float: right;
  }

  &-error {
    color: $mercury-unavailable-color;
  }

  &-forgot-password {
    font-family: $font-emphasis;
    font-weight: bold;

    &:hover {
      color: $mercury-accent-color;
    }
  }

  &-welcome {
    margin-top: 3rem;
    background-color: $mercury-alt-background-color;
    padding: 2rem;
  }

  &-register {
    margin-top: 2rem;

    &-button {
      margin-top: 2rem;
      text-align: center;
      float: right;
    }
  }
}
