// colors
$white: #FFFFFF;
$mercury-brand-color: #000000;
$mercury-accent-color-light: #FAD961;
$mercury-accent-color: #F5A623;
$mercury-accent-color-on-light: #926819;
$mercury-accent-color-dark: #F76B1C;
$mercury-text-color: #4A4A4A;
$mercury-inactive-color-light: #B1B1B1;
$mercury-inactive-color: #9B9B9B;
$mercury-alt-background-color: #DEDEDE;
$mercury-alt-dark-background-color: #242424;
$mercury-unavailable-color: #876020;
$mercury-quote-color: #FAFAFA;
$mercury-filter-bg-color: #1B1B1B;
$twitter-blue: #00ACED;

// break points
$bp-mobile: 480px;
$bp-mobile-width: 100%;
$bp-tablet: 600px;
$bp-tablet-width: 100%;
$bp-desktop: 1260px;
$bp-desktop-width: 1275px;

// header heights
$header-height-desktop: 50px;
$header-height-tablet: 15px;
$header-height-mobile: 85px;

// fonts
$font-main: "AvenirNext-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-emphasis: "AvenirNext-Demi", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-accent: "BWHaasGrotesk-95Black-Web", Helvetica, Arial, sans-serif;

// Mixins
@import "_when-than.scss";
@import "_mixins.scss";
