@import "_settings";

.saved--searches,
.publication--feeds {
  padding: 1rem 0;
}

.savedsearch-modal {
  padding: 1rem 2rem 4rem;
  font-size: 1.125rem;

  @include when-on(desktop) {
    width: $bp-mobile;
  }

  @include when-less-than(desktop) {
    width: 100%;
  }

  h4 {
    color: $white;
    text-align: center;
  }

  &--filter {
    margin-top: 2.75rem;
    margin-bottom: 1rem;

    &--query {
      input {
        background: $mercury-text-color;
        color: $white;
      }
    }
  }

  &-error {
    color: $mercury-accent-color-dark;
    margin-top: 1rem;
  }

  &-add-new {
    margin-top: 1.5rem;
    background: transparent;
    color: $white;
    font-family: $font-main;
    text-align: left;

    &::before {
      @extend %icon;
      @include convert-icon($mercury-accent-color);
      background-image: url("~icons/add@3x.png");
      margin-right: 2px;
      margin-top: 4px;
    }
  }

  %saved-search-button {
    color: $white;
    background: transparent;
    font-family: $font-main;
  }

  &__execute {
    @extend %saved-search-button;
    flex: auto;
    text-align: left;
    max-width: 80%;

    &-body {
      display: flex;
      align-items: center;
      word-break: break-word;
    }

    &-name {
      padding-right: 1rem;
    }

    &-carrot {
      color: $mercury-accent-color;
      font-size: 1rem;
    }
  }

  &__update {
    @extend %saved-search-button;

    &::before {
      @extend %icon;
      @include convert-icon($mercury-accent-color);
      background-image: url("~icons/add@3x.png");
      margin-right: 2px;
      margin-top: 4px;
    }
  }

  &__delete {
    @extend %saved-search-button;
    margin-left: 4px;

    &::before {
      @extend %icon;
      @include convert-icon($mercury-accent-color);
      background-image: url("~icons/YELLOW_REMOVE_ITEMS@3x.png");
    }
  }

  &__share {
    @extend %saved-search-button;
    margin-left: 4px;

    &::before {
      @extend %icon;
      @include convert-icon($mercury-accent-color);
      background-image: url("~icons/share@3x.png");
    }
  }

  &--list {
    margin-top: 1rem;
    max-height: 30vh;
    overflow-y: auto;

    &__actions {
      text-align: right;
      flex: auto;
      white-space: nowrap;
      position: relative;
      top: .1rem;
    }
  }

  li {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: .5rem;
  }
}
