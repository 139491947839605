@import "_settings";

.photo {
  display: grid;

  @include when-on(desktop) {
    width: $bp-desktop-width;
    margin: 0 auto;
    grid-template-columns: 1000px auto;
    grid-template-rows: auto auto 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    // sass-lint:disable-block indentation
    grid-template-areas:
      "image info"
      "commands info"
      "relatedImages info";
  }

  @include when-less-than(desktop) {
    // sass-lint:disable-block indentation
    grid-template-areas:
      "image"
      "commands"
      "info"
      "relatedImages";
  }

  &-image {
    grid-area: image;

    @include when-less-than(desktop) {
      margin-top: 1rem;
    }
  }

  &-info {
    grid-area: info;

    @include when-less-than(desktop) {
      margin-top: 2rem;
      padding: 0 1rem;
    }

    @include when-on(tablet) {
      display: inline-grid;
      grid-template-columns: 70% 30%;
      grid-column-gap: 1rem;
      // sass-lint:disable-block indentation
      grid-template-areas:
        "title ."
        "caption keywords"
        "caption information";
    }

    h1 {
      margin: 0;
      color: $mercury-text-color;

      @include when-on(tablet) {
        grid-area: title;
      }
    }

    h4 {
      font-size: 1.125rem;
      color: $mercury-text-color;
      margin-bottom: .5rem;
    }

    figcaption {
      margin-top: .5rem;
      line-height: 1.1;

      @include when-on(tablet) {
        grid-area: caption;
        align-self: stretch;
      }
    }

    &-categorization {
      @include when-on(tablet) {
        grid-area: keywords;
        margin-top: 0;

        > div:first-child {
          margin-top: 0;
        }
      }

      > div {
        margin-top: 1rem;
      }

      h4 {
        text-transform: capitalize;
      }
    }

    &-keyword {
      color: $mercury-accent-color-on-light;
      margin-right: .75rem;
      font-size: .875rem;
      font-weight: bold;
      font-family: $font-main;
      display: inline-block;
    }

    &-information {
      margin-top: 1rem;

      @include when-on(tablet) {
        grid-area: information;
      }

      span {
        display: block;
        margin-bottom: 5px;
      }
    }
  }

  &-commands {
    margin-top: 1rem;
    grid-area: commands;

    @include when-less-than(desktop) {
      padding: 0 1rem;
    }

    .navigation-bar--center {
      display: inline-flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    svg {
      display: inline-block;
      fill: $mercury-accent-color;
      width: 40px;
      height: 12px;
    }
  }
}
