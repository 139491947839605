@import "_settings";

$filters-width-desktop: 400px;
$filters-width-tablet: 280px;

@keyframes slide-in {
  0% { transform: translate($filters-width-desktop) }
  100% { transform: translate(0) }
}

@keyframes slide-out {
  0% { transform: translate(-$filters-width-desktop) }
  100% { transform: translate(0) }
}

@keyframes filters-slide-out {
  0% { transform: translate(0) }
  100% { transform: translate(-100%) }
}

@keyframes filters-slide-in {
  0% { transform: translate(-100%) }
  100% { transform: translate(0) }
}

.search {
  display: grid;
  grid-column-gap: 1rem;

  @include when-on(tablet) {
    margin-top: 1rem;
  }

  %slide-in {
    animation-name: slide-in;
    animation-duration: 350ms;
  }

  %slide-out {
    animation-name: slide-out;
    animation-duration: 350ms;
  }

  %filters-slide-in {
    animation-name: filters-slide-in;
    animation-duration: 350ms;
  }

  %filters-slide-out {
    animation-name: filters-slide-out;
    animation-duration: 350ms;
  }

  &-nav {
    @extend %slide-out;
    transition: transform 300ms ease-in-out;
    grid-area: header;
    padding-right: 1rem;

    @include when-on(tablet) {
      padding: 0;
    }
  }

  &-results {
    @extend %slide-out;
    grid-area: results;
    overflow-y: auto;

    @include when-on(desktop) {
      max-height: calc(100vh - #{$header-height-desktop} - 100px);
      overflow-y: hidden;

      &:hover {
        overflow-y: auto;
      }
    }

    @include when-on(tablet) {
      max-height: calc(100vh - #{$header-height-tablet} - 100px);
    }

    @include when-on(mobile) {
      max-height: calc(100vh - #{$header-height-mobile} - 100px);
    }
  }

  &.open {
    grid-template-rows: auto 1fr;
    // sass-lint:disable-block indentation
    grid-template-areas:
            "filters header"
            "filters results";

    .search-nav {
      padding-right: 1rem;
    }

    @include when-on(desktop) {
      grid-template-columns: $filters-width-desktop auto;
    }

    @include when-on(tablet) {
      grid-template-columns: $filters-width-tablet auto;

      .search-nav {
        padding: 1rem 1rem .5rem 0;
      }
    }

    @include when-on(mobile) {
      display: block;

      .search-nav {
        padding: .5rem 1rem;
      }

      .search-filters {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
      }
    }
  }

  &.closed {
    // sass-lint:disable-block indentation
    padding: 0 1rem;
    grid-template-areas:
            "header"
            "results";

    .filters {
      display: none;
    }

    .search-nav {
      @extend %slide-in;
    }

    .search-results {
      @extend %slide-in;
    }

    @include when-on(tablet) {

      .search-nav {
        padding: 1rem 1rem .5rem 0;
      }
    }

    @include when-on(mobile) {
      .search-nav {
        padding: .5rem 0;
      }
    }
  }

  .filters {
    @extend %filters-slide-in;
    grid-area: filters;
  }
}
