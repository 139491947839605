@import "_settings";

.pills {
  display: flex;
  flex-direction: column;
}

.pill {
  margin-top: .5rem;
  display: flex;

  &__label {
    color: $white;
    line-height: 1.2;
    flex-grow: 1;
  }

  &__remove {
    @extend %button-base;
    color: $white;
    border: 1px solid $mercury-accent-color;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: inline-flex;
    align-items: center;
    padding: 0;

    &::after {
      content: "\D7";
      align-self: center;
      display: inline-block;
      font-size: .75rem;
      width: 16px;
      height: 16px;
    }

    &:hover {
      background-color: $mercury-text-color;
    }
  }
}
