@import "_settings";

.options {
  display: flex;
  padding: .75rem 0;
  background-color: transparent;

  &__col {
    background-color: $mercury-brand-color;
    box-shadow: inset 1px 0 0 0 $mercury-text-color;

    &:first-child {
      box-shadow: none;
    }

    &--option {
      display: block;
      cursor: pointer;
      padding: 0 1rem;
      margin: 1.5rem 0;
      color: $mercury-inactive-color;

      &:hover,
      &.selected {
        color: $white;
        box-shadow: inset 2px 0 0 0 $mercury-accent-color;

        & .carat {
          color: $mercury-accent-color;
        }
      }
    }
  }
}
