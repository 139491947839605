@import "_settings";

.video-featured-series {

  @include when-on(mobile) {
    padding-top: 1rem;
  }

  // required overrides for styles set in the react-responsive-carousel module
  .carousel .slide .legend {
    padding: 0;
    left: 0;
    bottom: 0;
    margin: 0;
    width: 100%;
    opacity: 1;
    border-radius: 0;
    text-align: left;
    background-color: transparent;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .7) 25%, rgba(0, 0, 0, .4) 60%, transparent 100%);
  }

  img {
    max-height: 730px;
  }

  &--item {

    &--info {

      &-title {
        font-size: 1rem;
        font-weight: bolder;
        font-family: $font-accent;
        padding: 2rem 2rem 1rem;

        @include when-on(tablet) {
          font-size: 2rem;
        }

        @include when-on(desktop) {
          font-size: 3rem;
        }

        a {
          color: $white;
        }
      }

      &-summary {
        font-size: .8rem;
        padding: 0 2rem 2rem;

        @include when-on(mobile) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 2rem 1rem;
        }

        @include when-on(tablet) {
          font-size: .8rem;
        }

        @include when-on(desktop) {
          font-size: 1rem;
        }
      }
    }
  }
}
