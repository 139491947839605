@import "_settings";

.custom-checkbox {
  &-label {
    cursor: pointer;
    text-transform: capitalize;
  }

  &-input {
    display: none;

    + .custom-checkbox-text:before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 4px;
      border-radius: 2px;
      border: 1px solid $mercury-accent-color;
    }

    &:checked + .custom-checkbox-text:before {
      background: $mercury-accent-color;
    }
  }
}
