@import "_settings";

.search-go-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  padding: 1rem;
  font-family: $font-emphasis;
  color: $mercury-brand-color;
  background-color: $mercury-accent-color;

  @include when-on(desktop) {
    padding: 1rem;
  }

  @include when-less-than(desktop) {
    padding: .5rem;
  }
}
