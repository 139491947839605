@import "_settings";

.mercury-textinput {

  label {
    display: block;
    color: $mercury-brand-color;
    margin-bottom: .5rem;
  }

  input {
    width: 100%;
    border-bottom: 1px solid $mercury-accent-color;
    padding: .25rem;

    &::placeholder {
      color: $mercury-inactive-color;
    }
  }

  &-error {
    margin-top: .5rem;
    color: $mercury-unavailable-color;
  }
}
