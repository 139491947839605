@import "_settings";

.secondary-featured-series {
  display: flex;

  &--item {
    flex-grow: 1;
    width: 50%;
    border: 1px solid $white;
    background-color: $mercury-brand-color;

    img {
      width: 100%;
    }

    &-summary {
      padding: 1rem;
      color: $white;

      &.no-ellipsis {
        display: block;
      }

      &.ellipsis {
        display: none;
      }

      @include when-on(mobile) {
        padding: .5rem;

        &.no-ellipsis {
          display: none;
        }

        &.ellipsis {
          display: block;
        }
      }
    }
  }
}
