@import "_settings";

.video-player-modal {
  min-width: 50vw;

  @include when-on(mobile) {
    width: 100%;
  }

  .video-player {
    padding: 4rem 2rem 2rem;
  }
}
