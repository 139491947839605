@import "_settings";

%news--container {
  @include when-on(desktop) {
    width: 814px;
    margin: 0 auto;
  }

  @include when-less-than(desktop) {
    width: 100%;
    padding: 0 1rem;
  }
}

.news {
  color: $mercury-text-color;

  h1 {
    @extend %news--container;
    color: $mercury-text-color;
    font-size: 2.25rem;
    margin-bottom: 1rem;
    margin-top: 3rem;
    line-height: 2.5rem;

    @include when-on(mobile) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .navigation-bar {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &--content {
    position: relative;
  }


  &--lede {
    @extend %news--container;
    margin-bottom: 1.5rem;
  }

  &--published-info {
    @extend %news--container;
    font-weight: bold;
    font-family: $font-emphasis;
    font-size: .875rem;
    line-height: 1rem;

    @include when-on(mobile) {
      font-size: .75rem;
    }
  }

  article {
    @extend %news--container;
    counter-reset: body-footnotes;
    margin-top: 2rem;
    font-size: 1.125rem;
    line-height: 1.57;

    h3 {
      font-size: 1.125rem;
      margin: 7px 0;

      @include when-on(mobile) {
        font-size: 1rem;
      }
    }

    .article--attribution {
      margin-right: 5px;
    }

    .news-social-embedded {
      text-align: left;
      margin: 1rem 2rem;
      padding: 1rem;
      background-color: $mercury-quote-color;

      a {
        border-bottom: 0;
      }

      .news-social-person {
        display: inline-grid;
        grid-column-gap: .5rem;
        grid-template-areas:
                "profile displayname verified" // sass-lint:disable-line indentation
                "profile personurl ."; // sass-lint:disable-line indentation
      }

      .news-social-profile-image {
        grid-area: profile;
        border-radius: 50%;
      }

      .news-social-displayname {
        grid-area: displayname;
        font-weight: bold;
      }

      .news-social-verified-icon {
        grid-area: verified;
      }

      .news-social-person-url {
        grid-area: personurl;
        font-size: .825rem;
      }

      .news-social-content {
        margin-top: 1rem;
      }

      .news-social-origination {
        display: inline-flex;
        flex-wrap: wrap;
        margin-top: 1rem;
        font-size: .825rem;
        line-height: 20px;

        p {
          display: inline-block;
          margin-left: .5rem;

          &:first-of-type {
            margin-left: 0;
          }
        }
      }

      &[data-social-media-type="twitter"] {
        .news-social-verified-icon {
          &::before {
            @extend %icon;
            background-image: url("~icons/social/twitter-verified-icon.svg");
          }
        }

        a {
          color: $twitter-blue;
        }

        .news-social-origination {
          &::before {
            @extend %icon;
            height: 20px;
            background-image: url("~icons/social/twitter-logo-blue.png");
            align-self: center;
            margin-right: 4px;
          }
        }
      }
    }

    > p {
      margin: 10px 0;

      &:first-of-type {
        display: inline;
      }

      // if no actual attribution use css to add
      &:first-child {
        &::before {
          content: "(Bloomberg) -- ";
        }
      }
    }

    a {
      cursor: pointer;
      border-bottom: 1px dashed $mercury-accent-color;
    }

    li {
      margin-left: .5rem;
      margin-top: 8px;
    }

    ul {
      > li {
        list-style: disc inside;

        li {
          list-style: circle inside;
        }
      }
    }

    ol {
      > li {
        list-style: decimal inside;

        li {
          list-style: lower-alpha inside;
        }
      }
    }

    sup {
      display: inline-flex;
      background: $mercury-brand-color;
      color: $white;
      width: 12px;
      height: 12px;
      padding: 2px;
      justify-content: center;
      cursor: pointer;

      &::before {
        align-self: center;
        counter-increment: body-footnotes;
        content: counter(body-footnotes);
        font-size: 9px;
      }
    }

    figure {
      @include when-on(desktop) {
        margin: 2rem auto;
      }

      margin: 1rem auto;

      figcaption {
        margin-top: 10px;
        font-size: .625rem;
      }
    }

    table {
      border-collapse: collapse;
      width: 100%;

      th {
        padding: .75rem;
        border-bottom: 1px solid $mercury-brand-color;

        &:first-of-type {
          text-align: left;
        }

        &:not(first-of-type) {
          text-align: right;
        }
      }

      td {
        padding: .75rem;
        border-top: 1px dotted $mercury-brand-color;

        &:first-of-type {
          text-align: left;
        }

        &:not(first-of-type) {
          text-align: right;
        }
      }
    }
  }

  .article--trashline {
    margin-top: 1rem;
    font-style: italic;
  }

  .article--copyright {
    margin-top: 3rem;
  }

  .news-footnotes {
    counter-reset: news-footnotes;
    font-size: .625rem;

    .news-footnote {
      list-style: none;

      &::before {
        counter-increment: news-footnotes;
        content: counter(news-footnotes);
        margin-right: 8px;
        background: $mercury-brand-color;
        color: $white;
        width: 12px;
        text-align: center;
        display: inline-block;
      }
    }

    p {
      display: inline-block;
    }
  }

  &--commands {
    @extend %news--container;
    margin-top: 1.5rem;

    .navigation-bar--center {
      display: inline-flex;
      justify-content: space-between;
    }
  }

  &--attachments {
    @extend %news--container;
    margin-top: 1.5rem;

    h4 {
      font-size: 1.25rem;
    }

    &__attachments {
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;
    }

    &__attachment {
      flex-basis: 148px;
      margin-left: 1rem;
      margin-bottom: 1rem;
      position: relative;

      &.video {
        &:hover {
          cursor: pointer;
        }

        &::after {
          @extend %icon;
          @include convert-icon($white);
          width: 22px;
          height: 22px;
          background-image: url("~icons/video_only_ORG@3x.png");
          position: absolute;
          top: .5rem;
          right: .5rem;
        }
      }
    }
  }

  &--download {
    &__toggle {
      position: absolute;
      bottom: .5rem;
      right: .5rem;
      background: $white;
      padding: 2px 0 0;

      &::before {
        @extend %icon;
        width: 22px;
        height: 22px;
        background-image: url("~icons/download-comp@3x.png");
      }
    }

    &__options {
      position: absolute;
      bottom: calc(28px + .5rem);
      right: .5rem;
      font-size: .5625rem; // 9px
      background: $white;
      padding-right: 22px;


      &::after {
        @extend %icon;
        width: 22px;
        height: 22px;
        position: absolute;
        bottom: 4px;
        right: 0;
      }

      button,
      a {
        display: block;
        color: $mercury-accent-color;
        background: $white;
        padding: 4px 4px 4px .5rem;
        background-color: transparent;

        &:hover {
          background: $mercury-brand-color;
          color: $white;
        }
      }
    }
  }

  &--trialist {
    width: 100%;
    background: $mercury-brand-color;
    color: $white;
    font-size: 1.5rem;
    text-align: center;
    padding: 10px 0;
    margin-top: 1rem;
    position: relative;
    z-index: 1;

    @include when-on(mobile) {
      font-size: 1.125rem;
    }

    &--main {
      font-weight: bold;
    }

    &--note {
      margin-top: .5rem;
      font-size: 1rem;

      @include when-on(mobile) {
        font-size: .75rem;
      }
    }


    &__overlay {
      height: 100%;
      width: 100%;
      background: $white;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
}
