@import "_settings";

.resetpassword {
  width: $bp-mobile;
  margin: 0 auto;

  @include when-on(mobile) {
    margin-top: 2rem;
    padding: 0 1rem;
  }

  form {
    margin-top:  4rem;
  }

  button {
    float: right;
    margin-top: 2rem;
  }
}
