@import "_settings";

.registration {
  width: $bp-mobile;
  margin: 0 auto;

  @include when-on(mobile) {
    margin-top: 2rem;
    padding: 0 1rem;
  }

  &-success {
    margin-top: 2rem;

    strong {
      font-size: 2rem;
      margin-bottom: 1rem;
      display: block;
    }

    a {
      cursor: pointer;
      border-bottom: 1px dashed $mercury-accent-color;
    }

    p {
      margin-bottom: 1rem;
    }
  }

  &-error {
    color: $mercury-unavailable-color;
  }

  form {
    margin-top: 3rem;
  }

  &-columns {
    display: flex;

    @include when-wider-than(tablet) {
      justify-content: space-between;
    }

    @include when-on(mobile) {
      flex-wrap: wrap;
    }

    div {
      @include when-wider-than(tablet) {
        flex-basis: 48%;
      }

      @include when-on(mobile) {
        flex-basis: 100%;
      }
    }
  }

  .mercury-textinput {
    margin-bottom: 2rem;
  }

  &-passwords {
    legend {
      color: $mercury-text-color;

      @include when-wider-than(tablet) {
        font-size: .825rem;
        margin: 2rem 0 1rem;
      }

      @include when-on(mobile) {
        font-size: .75rem;
        margin: 1rem 0;
      }
    }
  }

  &-submit {
    float: right;
  }
}
