@import "_settings";

.story-download {
  align-self: stretch;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;

  &.close {
    .story-download-options {
      display: none;
    }
  }

  &.open {
    .button--download {
      color: $mercury-inactive-color;
    }
  }

  .button--download {
    color: $white;
    align-self: center;
    background-color: transparent;
  }

  svg {
    display: inline-block;
    fill: $mercury-accent-color;
    width: 20px;
    height: 12px;
  }

  &-options {
    background: $white;
    width: 90px;
    position: absolute;
    bottom: 0;
    right: calc(100% + 10px);
    z-index: 1;
    padding: .5rem 0;

    &::before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
      left: 100%;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid $white;
    }

    &-button {
      color: $mercury-brand-color;
      font-size: .625rem;
      padding: 2px .5rem;
      display: block;
      width: 100%;
      text-align: left;
      background-color: transparent;

      &:hover {
        color: $white;
        background: $mercury-brand-color;
      }
    }

    &-button-inactive {
      color: $mercury-inactive-color-light;
      font-size: .625rem;
      padding: 2px .5rem;
      display: block;
      width: 100%;
      text-align: left;
      background-color: transparent;
    }
  }
}
